import { Container } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FiltersAction } from '../actions/filters.actions'
import Filters from '../components/dashboard/admin/filters.component'
import Notification from '../components/notification.components'
import { FILTERS_RESET } from '../constants/filters.constants'
import { FiltersState } from '../reducers/filters.reducer'
import { ScreenState } from '../reducers/screen.reducer'
import store, { RootState } from '../store'
import { TYPE_BLACKLIST, TYPE_WHITELIST } from '../types/filter.types'

const FiltersScreen = (props: any) => {
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [filterCategoryBy, setFilterCategory] = useState('')
    const [filterTypeBy, setFilterTypeBy] = useState('')
    const { options } = useSelector<RootState, ScreenState>(
        (state) => state.screen
    )
    const filters = useSelector<RootState, FiltersState>(
        (state) => state.filters
    )
    const isLoading = props.isLoading || filters.loading

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            store.dispatch(
                FiltersAction.index(
                    1,
                    searchTerm,
                    filterCategoryBy,
                    filterTypeBy
                )
            )
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [page, searchTerm, filterCategoryBy, filterTypeBy])

    useEffect(() => {
        props.getScreenTitle('Filters')
    }, []) // eslint-disable-line

    const onPageChange = (page: number) => {
        setPage(page)
        store.dispatch(FiltersAction.index(page))
    }

    const onFilterCategoryChange = (value: string) => {
        setFilterCategory(value)
        store.dispatch(
            FiltersAction.index(page, searchTerm, value, filterTypeBy)
        )
    }

    const onFilterTypeChange = (value: string) => {
        setFilterTypeBy(value)
        store.dispatch(
            FiltersAction.index(page, searchTerm, filterCategoryBy, value)
        )
    }

    const setBlacklist = (filterDetails: any) => {
        store.dispatch(
            FiltersAction.updateType(filterDetails.id, { type: TYPE_BLACKLIST })
        )
    }
    const setWhitelist = (filterDetails: any) => {
        store.dispatch(
            FiltersAction.updateType(filterDetails.id, { type: TYPE_WHITELIST })
        )
    }

    const deleteFilter = (filterDetails: any) => {
        store.dispatch(FiltersAction.delete(filterDetails.id))
    }

    return (
        <>
            <Container className="container">
                <Filters
                    list={filters.data?.list?.data ?? []}
                    meta={filters.data?.list?.meta ?? { last_page: 0 }}
                    page={page}
                    onPageChange={onPageChange}
                    onSearch={setSearchTerm}
                    getRightNav={props.getRightNav}
                    showRightSideBar={props.showRightSideBar}
                    onFilterTypeChange={onFilterTypeChange}
                    onFilterCategoryChange={onFilterCategoryChange}
                    filterCategoryBy={filterCategoryBy}
                    filterTypeBy={filterTypeBy}
                    options={options ?? {}}
                    setBlacklist={setBlacklist}
                    setWhitelist={setWhitelist}
                    deleteFilter={deleteFilter}
                    isLoading={isLoading}
                    isDesktop={props.isDesktop}
                />
            </Container>
            <Notification
                message={filters.data?.message ?? 'An error has occurred.'}
                open={filters.showNotification}
                severity={filters.hasError ? 'error' : 'success'}
                onClose={() =>
                    store.dispatch({
                        type: FILTERS_RESET,
                    })
                }
            />
        </>
    )
}

export default FiltersScreen
