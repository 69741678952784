import { Avatar, Badge, Drawer, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { RightNavProps } from '../../routes/protected'
import { useEffect } from 'react'
import { Box } from '@mui/system'

type Wallet = {
    balance: number
    spending: number
}

type Props = {
    isOpen: boolean
    wallet?: Wallet
    showRightSideBar: any
    isDesktop: boolean
    isRightSideBarOpen: boolean
    rightNav?: RightNavProps | null
}

export default function RightSideBar(props: Props) {
    const {
        isOpen,
        showRightSideBar,
        rightNav,
        isDesktop,
        isRightSideBarOpen,
    } = props

    useEffect(() => {
        if (isRightSideBarOpen) {
            const ambot = document.querySelector(
                '.MuiModal-root .MuiPaper-root'
            ) as HTMLElement
            if (ambot) {
                ambot.style.height = `${window.innerHeight}px`
            }
        }
    }, [isRightSideBarOpen])

    return (
        <>
            <Drawer
                sx={{
                    borderRadius: '40px',
                    flexShrink: 0,
                    '.MuiPaper-root': {
                        width: '375px',
                        background: '#FFFFFF',
                        borderRadius: '40px',
                        border: 'none',
                        '& h2.title': {
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 0,
                        },
                    },
                    '&.mobile': {
                        '.MuiPaper-root': {
                            width: '100%',
                            height: '100%',
                            '& h2.title': {
                                justifyContent: 'center',
                            },
                        },
                    },
                    '& .MuiBackdrop-root': { bgcolor: 'unset' },
                }}
                variant="temporary"
                anchor={isDesktop ? 'right' : 'top'}
                className={isDesktop ? '' : 'mobile'}
                open={isOpen}
                onBackdropClick={showRightSideBar}
            >

          
                <Box
                    sx={{
                        padding: '30px',
                        overflow: 'auto',
                        paddingTop: !isDesktop ? '120px' : '30px',
                        paddingBottom: !isDesktop ? '100px' : '30px',
                        ...(rightNav?.parentCustomStyle ?? {}),
                    }}
                >
                  

                    <h2 className="title" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        {rightNav?.title ?? ''}
                        {isDesktop && (
                            <CloseIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={showRightSideBar}
                            />
                        )}

                        {(isRightSideBarOpen && !isDesktop) && (
                              <IconButton
                                  className={`upviews-badge${
                                      isRightSideBarOpen ? ' close' : ''
                                  }`}
                                  color="inherit"
                                  onClick={showRightSideBar}
                              >
                                  <Badge badgeContent="">
                                      <Avatar>
                                          <CloseIcon />
                                      </Avatar>
                                  </Badge>
                              </IconButton>
                          )}
                    </h2>
                    {rightNav?.content ?? null}
                </Box>
            </Drawer>
        </>
    )
}
