import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const InstagramService = {
  get: async (instagramUser: string) => {
    const config: AxiosRequestConfig = {
      baseURL: 'https://instagram-scraper-2022.p.rapidapi.com',
      timeout: 10000,
      headers: {
        'X-RapidAPI-Key': '4d3d848483msh7ec67f7b6886781p1a7f35jsnd578e2fd2ba1',
        'X-RapidAPI-Host': 'instagram-scraper-2022.p.rapidapi.com'
      }
    };

    const API_INSTANCE: AxiosInstance = axios.create(config);
    return API_INSTANCE.get('/ig/web_profile_info/?user=' + instagramUser);
  },
}