import { OutlinedInput } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const styles = {
    marginBottom: '20px',
    '& label': {
        marginBottom: '10px',
    },
}

const CustomLabeledFormControl = (props: any) => {
    const {
        label,
        onChange,
        value,
        size = 'small',
        fullWidth = true,
        error = false,
        customChildren = null,
        ...otherProps
    } = props
    return (
        <Box sx={styles}>
            <label>{label}</label>
            {customChildren ? (
                customChildren
            ) : (
                <OutlinedInput
                    fullWidth={fullWidth}
                    size={size}
                    onChange={onChange}
                    error={error}
                    value={value}
                    {...otherProps}
                />
            )}
        </Box>
    )
}

export default CustomLabeledFormControl
