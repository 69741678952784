import axios, {
    AxiosRequestConfig,
    AxiosInstance,
    AxiosRequestHeaders,
} from 'axios'
import { TYPES_CONST } from '../env'
import { getToken } from './auth.service'
import endpoints, { requiresCSRFToken } from './endpoints'

const config: AxiosRequestConfig = {
    baseURL: TYPES_CONST.BASE_URL,
    timeout: process.env.NODE_ENV !== 'production' ? 20000 : 10000,
}

const API_INSTANCE: AxiosInstance = axios.create(config)

const fetchCSRFToken = async () =>
    await API_INSTANCE.get(endpoints.CSRF_TOKEN, {
        withCredentials: true,
    })

API_INSTANCE.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (
        !config.headers?.[config.xsrfHeaderName as string] &&
        requiresCSRFToken(config.url ?? '')
    ) {
        await fetchCSRFToken()
    }

    config.withCredentials = true

    return config
})

// Request interceptor: add Bearer token
API_INSTANCE.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const accessToken = getToken()
    if (accessToken) {
        ;(
            config.headers as AxiosRequestHeaders
        ).Authorization = `Bearer ${accessToken}`
    }
    return config
})

export default API_INSTANCE

export function objectToQueryParams(objectParam: any): string {
    let params = {}

    for (var key in objectParam) {
        if (objectParam[key]) {
            params = { ...params, ...{ [key]: objectParam[key] } }
        }
    }

    return new URLSearchParams(params).toString()
}
