import { useEffect, useState } from 'react'
import {
    Avatar,
    Box,
    Grow,
    IconButton,
    Popover,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { getCurrentUser } from '../../services/auth.service'
import CustomButton from '../customComponents/custom-button.component'
import ProfileMenu from './profile-menu.component'
import { ReactComponent as WalletIcon } from '../../assets/images/icons/wallet.svg'
import { getProfilePic } from '../../services/account.service'

interface balanceProps {
    isAdded: boolean
    show: boolean
    value: number
}

export default function NavBar(props: any) {
    const {
        isDesktop,
        customClassName,
        showRightSideBar,
        title = '',
        user,
        wallet,
        isLoading,
    } = props
    const currentUser = getCurrentUser() || {}
    let roles = currentUser.roles ?? []
    let isCustomer = roles.length === 0

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const isProfileMenuOpen = Boolean(anchorEl)
    const [balanceUpdates, setBalanceUpdates] = useState<balanceProps[]>([])
    const currentBalance = parseFloat(
        localStorage.getItem('currentBalance') ?? '0'
    )

    useEffect(() => {
        if (!isLoading && !wallet.loading && wallet?.balance) {
            const walletBalance = wallet?.balance ?? 0
            /**
             * First Wallet load
             * Set initial wallet balance
             **/
            if (!currentBalance) {
                localStorage.setItem('currentBalance', walletBalance)
                return
            }

            // Set current balance and show deductions and additions
            if (walletBalance > currentBalance) {
                setBalanceUpdates([
                    ...balanceUpdates,
                    {
                        isAdded: true,
                        show: true,
                        value: walletBalance - currentBalance,
                    },
                ])
            } else if (walletBalance < currentBalance) {
                setBalanceUpdates([
                    ...balanceUpdates,
                    {
                        isAdded: false,
                        show: true,
                        value: currentBalance - walletBalance,
                    },
                ])
            }
            localStorage.setItem('currentBalance', walletBalance)
        }
    }, [wallet]) // eslint-disable-line

    useEffect(() => {
        if (balanceUpdates.length > 0) {
            setTimeout(() => {
                setBalanceUpdates(
                    balanceUpdates.map((e) => ({ ...e, show: false }))
                )
            }, 3000)
        }
    }, [balanceUpdates.length]) // eslint-disable-line

    const handleOpenProfileMenu = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseProfileMenu = () => {
        setAnchorEl(null)
    }

    const getAvatar = () => {
        let photoSrc = getProfilePic(user)

        return photoSrc ? (
            <Avatar
                sx={{
                    width: 38,
                    height: 38,
                    bgcolor: '#' + user?.info?.avatar ?? '2ecc71',
                }}
                src={photoSrc}
            />
        ) : (
            <Avatar sx={{ width: 38, height: 38, bgcolor: '#2ecc71' }}>
                {user?.info?.name?.charAt(0)}
            </Avatar>
        )
    }

    return (
        <Box
            sx={{
                '& .MuiToolbar-root': {
                    pr: '20px',
                    pt: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& .MuiTypography-root': {
                        flexGrow: 1,
                        fontWeight: 700,
                        fontSize: '24px',
                        color: '#303030',
                    },
                    '& .profile': {
                        bgcolor: '#FFFFFF',
                        borderRadius: '30px',
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        ml: '10px',
                        '& .MuiAvatar-root': {
                            marginRight: '10px',
                        },
                        '& p': {
                            margin: '0px',
                            color: '#212121',
                            fontWeight: 600,
                            fontSize: '17px',
                            pr: '20px',
                        },
                    },
                    '& .MuiButton-root': {
                        backgroundColor: '#27A857',
                        borderRadius: '30px',
                        fontWeight: 700,
                        fontSize: '18px',
                        boxShadow: 'none',
                        padding: '10px 20px',
                        color: '#fff',
                        width: '225px',
                    },
                },
                '&.dashboard-nav': {
                    '& .MuiToolbar-root': {
                        display: 'flex', //{ md: 'block', lg: 'flex' },
                    },
                },
            }}
            className={customClassName || ''}
        >
            <Toolbar>
                <Box
                    mt={{
                        xs: '100px',
                        md: '0px',
                        width: isDesktop ? '60%' : '100%',
                    }}
                >
                    <Typography
                        textAlign={{ xs: 'center', md: 'left' }}
                        component="h1"
                        variant="h6"
                        color="inherit"
                    >
                        {title}
                    </Typography>
                </Box>
                {isDesktop && (
                    <Box display="flex">
                        <Stack direction="row" spacing={2} className="profile">
                            {getAvatar()}
                            <Box
                                sx={{
                                    margin: 0,
                                    color: '#212121',
                                    fontWeight: 600,
                                    fontSize: '17px',
                                    ml: 'unset!important',
                                }}
                            >
                                {currentUser.name}
                            </Box>
                            <IconButton
                                sx={{
                                    ml: 'unset',
                                    '& svg': {
                                        background: '#EBEBEB',
                                        borderRadius: '7px',
                                        color: '#111113',
                                    },
                                }}
                                disableRipple
                                aria-describedby={'profile-popover'}
                                onClick={handleOpenProfileMenu}
                            >
                                {isProfileMenuOpen ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </IconButton>
                            <Popover
                                id={'profile-popover'}
                                open={isProfileMenuOpen}
                                anchorEl={anchorEl}
                                onClose={handleCloseProfileMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '& .MuiPopover-paper': {
                                        mt: '20px',
                                        ml: '10px',
                                        borderRadius: '10px',
                                    },
                                    '& .MuiList-root': {
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        '& li': {
                                            fontSize: '14px',
                                            padding: '5px 15px',
                                            '&:hover': {
                                                backgroundColor: '#FFE2E2',
                                                color: '#FF4848',
                                                cursor: 'pointer',
                                                '& svg': {
                                                    color: '#FF4848',
                                                },
                                            },
                                            '& svg': {
                                                color: '#BABEC8',
                                                fontSize: '21px',
                                            },
                                            '& .MuiTypography-root': {
                                                fontSize: '14px',
                                            },
                                            '& .MuiListItemIcon-root': {
                                                minWidth: '30px',
                                            },
                                        },
                                    },
                                }}
                            >
                                <ProfileMenu onClose={handleCloseProfileMenu} />
                            </Popover>
                        </Stack>
                        {isCustomer && (
                            <Box position="relative">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        backgroundColor: '#fff',
                                        boxShadow:
                                            '0px 40px 60px rgba(128, 144, 155, 0.25)',
                                        borderRadius: '30px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: '10px',
                                        '& span': {
                                            ml: '0px',
                                        },
                                        '& > svg': {
                                            color: '#545454',
                                            paddingLeft: '20px',
                                            fontSize: '30px',
                                        },
                                        '& p': {
                                            margin: '0 !important',
                                            fontSize: '24px',
                                            fontWeight: '800',
                                            color: '#27A857',
                                            padding: '0 10px',
                                        },
                                        button: {
                                            width: 'auto !important',
                                            fontSize: '18px',
                                            padding: '12px 17px !important',
                                        },
                                    }}
                                >
                                    <WalletIcon />
                                    <p>${wallet?.balance ?? 0}</p>
                                    <CustomButton
                                        className="upviews-button"
                                        onClick={showRightSideBar}
                                        endIcon={<AddIcon />}
                                        width="unset"
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mt: '10px',
                                        right: '0',
                                        '.balance': {
                                            fontSize: '14px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 'bold',
                                            borderRadius: '30px',
                                            padding: '5px 10px',
                                            mb: '5px',
                                            span: {
                                                mr: '5px',
                                                fontSize: '20px',
                                            },
                                            '&.add': {
                                                bgcolor: '#27a85745',
                                                color: '#27A857',
                                            },
                                            '&.deduct': {
                                                bgcolor: '#ff3e3e45',
                                                color: '#FE382B',
                                            },
                                        },
                                    }}
                                >
                                    {balanceUpdates.length > 0 &&
                                        balanceUpdates.map(
                                            ({ isAdded, show, value }) => (
                                                <Grow
                                                    in={show}
                                                    style={{
                                                        transformOrigin:
                                                            '0 0 0',
                                                        position: 'absolute',
                                                        right: '0',
                                                    }}
                                                    timeout={1000}
                                                >
                                                    <div
                                                        className={`balance ${
                                                            isAdded
                                                                ? 'add'
                                                                : 'deduct'
                                                        }`}
                                                    >
                                                        <span>
                                                            {isAdded
                                                                ? '+'
                                                                : '-'}
                                                        </span>{' '}
                                                        {isAdded
                                                            ? 'Added'
                                                            : 'Deducted'}{' '}
                                                        ${value.toFixed(2)}
                                                    </div>
                                                </Grow>
                                            )
                                        )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Toolbar>
        </Box>
    )
}
