import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import FundsComponent from '../components/dashboard/customer/funds.component'
import LeftSideBar from '../components/dashboard/left-sidebar.component'
import NavBar from '../components/dashboard/navbar.component'
import RightSideBar from '../components/dashboard/right-sidebar.component'
import { UserState } from '../reducers/user.reducer'
import { WalletState } from '../reducers/wallet.reducer'
import store, { RootState } from '../store'
import { IsDesktopScreen } from '../helpers/utils.helper'
import { TransactionState } from '../reducers/transaction.reducer'
import { Modal } from '@mui/material'
import DepositFund from '../components/dashboard/deposit-fund.component'
import DepositSuccessModal from '../components/dashboard/deposit-success-modal.component'
import { TRANSACTION_RESET } from '../constants/transaction.constants'
import { WalletAction } from '../actions/account.action'
import { getOptions } from '../actions/screen.action'
import { ScreenState } from '../reducers/screen.reducer'
import { FileAction } from '../actions/file.action'

export type ProtectedRouteProps = {
    isAuthenticated: boolean
    authenticationPath: string
    Outlet: Function
}

export type RightNavProps = {
    title?: string
    content?: JSX.Element
    parentCustomStyle?: object
}

export default function ProtectedRoute({
    isAuthenticated,
    authenticationPath,
    Outlet,
}: ProtectedRouteProps) {
    const wallet = useSelector<RootState, WalletState>((state) => state.wallet)
    const transaction = useSelector<RootState, TransactionState>(
        (state) => state.transaction
    )
    const screen = useSelector<RootState, ScreenState>((state) => state.screen)
    const user = useSelector<RootState, UserState>((state) => state.userLogin)
    const [isRightSideBarOpen, setIsRightSideBarOpen] = useState(false)
    const [rightNav, setRightNav] = useState<null | RightNavProps>(null)
    const [screenTitle, setScreenTitle] = useState('')
    const [openTrxModal, setOpenTrxModal] = useState(false)

    const roles = user?.info?.roles ?? []
    const isCustomer = user?.info?.roles && roles.length === 0
    const isDesktop = IsDesktopScreen()
    const isLoading = user?.loading

    useEffect(() => {
        if (isCustomer) {
            setDefaultRightNav()
        }
        getProtectedResources(isCustomer, screen, wallet, user)
    }, [isCustomer, wallet, user, screen]) // eslint-disable-line

    if (!isDesktop && !window.location.pathname.includes('/support')) {
        ;(window as any).tidioChatApi?.hide()
    } else {
        ;(window as any).tidioChatApi?.show()
    }

    const getProtectedResources = (
        isCustomer: boolean,
        screen: ScreenState,
        wallet: WalletState,
        user: UserState
    ) => {
        if (user.loading || user.info === null) {
            return
        }

        !screen.hasError &&
            !screen.loading &&
            screen.options === null &&
            store.dispatch(getOptions())

        isCustomer &&
            wallet.balance == null &&
            !wallet.loading &&
            store.dispatch(WalletAction.get())

        user.info?.profile_photo &&
            !user.photo &&
            store.dispatch(FileAction.viewByUrl(user.info.profile_photo))
    }

    const setDefaultRightNav = () => {
        handleSetRightNav({
            title: 'Your Funds',
            content: (
                <FundsComponent
                    showRightSideBar={showRightSideBar}
                    getRightNav={handleSetRightNav}
                    isRightSideBarOpen={isRightSideBarOpen}
                    isDesktop={isDesktop}
                    wallet={wallet}
                    transaction={transaction}
                    openTrxModal={setOpenTrxModal}
                />
            ),
        })
    }

    const handleSetRightNav = (rightNav: RightNavProps) => {
        setRightNav(rightNav)
    }

    const showRightSideBar = () => {
        setIsRightSideBarOpen(!isRightSideBarOpen)
        if (isRightSideBarOpen) {
            setDefaultRightNav()
        }
    }

    if (!isAuthenticated) {
        return <Navigate to={{ pathname: authenticationPath }} />
    }

    return (
        <Box display="flex">
            <LeftSideBar
                isLoading={isLoading}
                isDesktop={isDesktop}
                isRightSideBarOpen={isRightSideBarOpen}
                showRightSideBar={showRightSideBar}
                getRightNav={handleSetRightNav}
                transaction={transaction}
                wallet={wallet}
            />
            <Box
                className={`container-wrapper`}
                pb={{ xs: '100px', md: '50px' }}
            >
                <main className="content">
                    <NavBar
                        isLoading={isLoading}
                        isDesktop={isDesktop}
                        showRightSideBar={showRightSideBar}
                        customClassName="dashboard-nav"
                        title={screenTitle}
                        wallet={wallet}
                        transaction={transaction}
                        user={user}
                    />
                    <div className="spacer" />
                    <Outlet
                        isDesktop={isDesktop}
                        isLoading={isLoading}
                        wallet={wallet}
                        getRightNav={handleSetRightNav}
                        showRightSideBar={showRightSideBar}
                        getScreenTitle={setScreenTitle}
                        openTrxModal={setOpenTrxModal}
                    />
                </main>
            </Box>
            <RightSideBar
                isDesktop={isDesktop}
                isOpen={isRightSideBarOpen}
                rightNav={rightNav}
                showRightSideBar={showRightSideBar}
                isRightSideBarOpen={isRightSideBarOpen}
            />
            {isDesktop && (
                <>
                    <Modal open={openTrxModal && !transaction.showModal}>
                        <div>
                            <DepositFund
                                onClose={() => setOpenTrxModal(false)}
                                isDesktop={isDesktop}
                                transaction={transaction}
                            />
                        </div>
                    </Modal>

                    <DepositSuccessModal
                        open={transaction.showModal}
                        transaction={transaction}
                        onClose={() => {
                            setOpenTrxModal(false)
                            store.dispatch({
                                type: TRANSACTION_RESET,
                            })
                        }}
                    />
                </>
            )}
        </Box>
    )
}
