import { TYPES_CONST } from '../env'
import { TYPE_SUPER_ADMIN, TYPE_WORKER } from '../types/user.types'
import API_INSTANCE from './api'
import endpoints from './endpoints'

export function setAuth(value: any, hasUser: boolean = false) {
    if (value === undefined) {
        return
    }
    if (hasUser) {
        setCurrentUser(value.user)
        localStorage.setItem(TYPES_CONST.AUTH_NAME, value.token)
        return
    }
    localStorage.setItem(TYPES_CONST.AUTH_NAME, value)
}

export function getToken() {
    return localStorage.getItem(TYPES_CONST.AUTH_NAME)
}

export function setCurrentUser(value: string | undefined) {
    if (value === undefined) {
        return
    }

    localStorage.setItem(TYPES_CONST.INFO_NAME, JSON.stringify(value))
}

export function getCurrentUser() {
    const userStr = localStorage.getItem(TYPES_CONST.INFO_NAME)
    if (!userStr) {
        return null
    }

    return JSON.parse(userStr)
}

export function removeAuth() {
    localStorage.removeItem(TYPES_CONST.INFO_NAME)
    return localStorage.removeItem(TYPES_CONST.AUTH_NAME)
}

export const VipDetailsService = async (queryParams: string) => {
    return API_INSTANCE.get(endpoints.GET_VIP_INFO + queryParams)
}

export const LoginService = async (fields: any) => {
    return API_INSTANCE.post(endpoints.LOGIN, fields)
}

export const RegisterService = async (fields: any) => {
    return API_INSTANCE.post(endpoints.REGISTER, fields)
}

export const InfoService = async () => {
    return API_INSTANCE.get(endpoints.INFO)
}

export const isUserAdmin = (userInfo: any) => {
    if (!Array.isArray(userInfo?.roles)) {
        return false
    }
    return (
        (userInfo?.roles ?? []).filter((role: string) =>
            [TYPE_SUPER_ADMIN, TYPE_WORKER].includes(role)
        ).length > 0
    )
}

export const RecoveryService = async (fields: any) => {
    return API_INSTANCE.post(endpoints.RECOVERY, fields)
}

export const ResetService = async (fields: any) => {
    return API_INSTANCE.post(endpoints.RESET, fields)
}
