import {
    INFO_VIP_REQUEST,
    INFO_VIP_SUCCESS,
    INFO_VIP_FAIL,
    VIP_RESET,
} from '../constants/user.constants'

export interface VipState {
    loading?: boolean
    error?: string
    info?: any
    showNotification: boolean
    hasError: boolean
}

interface Action {
    type: string
    payload?: any
}

export const vipReducer = (
    state: VipState = {
        info: null,
        showNotification: false,
        loading: false,
        hasError: false,
    },
    action: Action
) => {
    switch (action.type) {
        case INFO_VIP_REQUEST:
            return { ...state, loading: true }
        case INFO_VIP_SUCCESS:
            console.log('INFO_VIP_SUCCESS', action)
            return {
                loading: false,
                info: action?.payload?.settings ?? null,
                isAuth: true,
                hasError: false,
            }
        case INFO_VIP_FAIL:
            return {
                loading: false,
                error: action.payload,
                showNotification: true,
                hasError: true,
            }
        case VIP_RESET:
            return {
                loading: false,
                showNotification: false,
            }
        default:
            return state
    }
}
