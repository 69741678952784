import {
    ORDER_ACTION_FAILED,
    ORDER_ACTION_SUCCESS,
    ORDER_ACTION_UPLOAD_FAIL,
    ORDER_ACTION_UPLOAD_REQUEST,
    ORDER_CANCEL_FAILED,
    ORDER_CANCEL_SUCCESS,
    ORDER_FAIL,
    ORDER_GET_SUCCESS,
    ORDER_ISSUE_DOWNLOAD_FAIL,
    ORDER_REQUEST,
    ORDER_RESEND_FAILED,
    ORDER_RESEND_SUCCESS,
    ORDER_RESET,
    ORDER_SUCCESS,
    ORDER_UPLOAD_FAIL,
    ORDER_UPLOAD_REQUEST,
    ORDER_INITIATE_UPLOAD_REQUEST,
} from '../constants/orders.constants'

type DataOrder = {
    message?: string | null
    list: {
        data: Array<object>
        meta: object | null
    }
}
export interface OrderState {
    loading?: boolean
    showNotification: boolean
    hasError?: boolean
    data: DataOrder
    order: any
    isUploading?: boolean
    isBalanceNotEnough?: boolean
    isOrderSuccess?: boolean
    isUrlDuplicate?: boolean
}

interface Action {
    type: string
    payload?: any
}
export const ordersReducer = (
    state: OrderState = {
        loading: false,
        isUploading: false,
        showNotification: false,
        hasError: false,
        order: null,
        isOrderSuccess: false,
        data: {
            message: null,
            list: {
                data: [],
                meta: null,
            },
        },
    },
    action: Action
) => {
    switch (action.type) {
        case ORDER_REQUEST:
            return {
                loading: true,
                isUploading: false,
                showNotification: false,
                hasError: false,
                order: state.order,
                data: {
                    message: null,
                    list: state.data.list,
                },
            }
        case ORDER_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: false,
                order: action.payload,
                isOrderSuccess: true,
                isUrlDuplicate: false,
                data: {
                    message: 'Order successfully placed.',
                    list: state.data.list,
                },
            }
        case ORDER_GET_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: false,
                hasError: false,
                order: action.payload,
                data: { list: action.payload },
            }
        case ORDER_FAIL:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                order: state.order,
                isOrderSuccess: false,
                isBalanceNotEnough: !!action.payload?.errors?.balance,
                isUrlDuplicate: !!action.payload?.errors?.order,
                data: { ...state.data, message: action.payload?.message },
            }
        case ORDER_RESEND_FAILED:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                order: state.order,
                data: { ...state.data, message: action.payload?.message },
            }
        case ORDER_RESEND_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: false,
                order: state.order,
                data: {
                    message: 'Order successfully resend.',
                    list: state.data.list,
                },
            }
        case ORDER_CANCEL_FAILED:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                order: state.order,
                data: { ...state.data, message: action.payload?.message },
            }
        case ORDER_CANCEL_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: false,
                order: state.order,
                data: {
                    message: 'Order successfully cancelled.',
                    list: state.data.list,
                },
            }
        case ORDER_ACTION_SUCCESS:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: false,
                order: state.order,
                data: {
                    message: 'Order successfully updated.',
                    list: state.data.list,
                },
            }
        case ORDER_ACTION_FAILED:
            return {
                loading: false,
                isUploading: false,
                showNotification: true,
                hasError: true,
                order: state.order,
                data: {
                    ...state.data,
                    message: action.payload?.message,
                },
            }
        case ORDER_ACTION_UPLOAD_FAIL:
            return {
                loading: false,
                isUploading: false,
                data: {
                    message:
                        'Order is updated, but there is an issue uploading the issue.',
                    list: state.data.list,
                },
                hasError: true,
                showNotification: true,
                order: state.order,
            }
        case ORDER_ISSUE_DOWNLOAD_FAIL:
            return {
                loading: false,
                isUploading: false,
                data: {
                    message: 'Unable to download pdf.',
                    list: state.data.list,
                },
                hasError: true,
                showNotification: true,
                order: state.order,
            }
        case ORDER_UPLOAD_FAIL:
            return {
                loading: false,
                isUploading: false,
                data: {
                    message:
                        'Campaign is created, but there is an issue with uploading thumbnail.',
                    list: state.data.list,
                },
                hasError: true,
                showNotification: true,
                order: action.payload,
            }
        case ORDER_UPLOAD_REQUEST:
            return {
                isUploading: true,
                order: action.payload,
                data: state.data,
                hasError: false,
                showNotification: false,
                loading: true,
            }
        case ORDER_INITIATE_UPLOAD_REQUEST:
            return {
                ...state,
                isUploading: true,
            }
        case ORDER_ACTION_UPLOAD_REQUEST:
            return {
                isUploading: false,
                order: action.payload,
                data: {
                    message: 'Order successfully updated.',
                    list: state.data.list,
                },
                hasError: false,
                showNotification: true,
            }
        case ORDER_RESET:
            return {
                isUploading: false,
                loading: false,
                data: state.data,
                showNotification: false,
                isBalanceNotEnough: false,
                order: state.order,
                hasError: false,
                isOrderSuccess: false,
            }
        default:
            return state
    }
}
