import { useState } from 'react'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import { formatDate } from '../../../helpers/utils.helper'
import TableWrapper from '../../wrappers/table-wrapper.component'
import YoutubeEmbed from '../youtube-embed.component'
import { RootState } from '../../../store'
import { ScreenState } from '../../../reducers/screen.reducer'
import { useNavigate } from 'react-router'
import DownloadIssue from './download-issue.component'
import { OrderService } from '../../../services/orders.service'
import TextSkeleton from '../../skeletons/text.skeleton'
import Bundle from '../../../assets/images/bundle-blue.png'
import CustomStatus from '../../customComponents/custom-status.component'
import { PLATFORM_YOUTUBE } from '../../../types/order.types'
import { VIEW_TYPE_TARGETED } from '../../../types/order_status.types'

export default function DashboardOrders(props: any) {
    const { onSearch, list, meta, onSort, onPageChange, isLoading } = props
    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}
    const { options } = useSelector<RootState, ScreenState>(
        (state) => state.screen
    )
    const navigate = useNavigate()

    const [sortBy, setSortBy] = useState('')

    const headers = [
        { key: 'code', label: 'Order No.' },
        {
            key: 'created_at',
            label: `Date Added`,
            customValue: (row: any) => formatDate(row.created_at, 'l LT'),
        },
        { key: 'youtube_url', label: 'URL' },
        {
            key: 'current_views',
            label: 'Current',
            customValue: (row: any) =>
                `${row?.current_views ?? 0} ` +
                OrderService.getTargetSubject(row?.service),
        },
        {
            key: 'target_count',
            label: 'Wanted',
            customValue: (row: any) =>
                `${row.target_count} ` +
                OrderService.getTargetSubject(row?.service),
        },
        {
            key: 'start_count',
            label: 'Start',
            customValue: (row: any) =>
                `${row.start_count} ` +
                OrderService.getTargetSubject(row?.service),
        },
        {
            key: 'status',
            label: 'Status',
            customValue: (row: any) => {
                const status = row?.status || 'pending'
                return <CustomStatus status={status} />
            },
        },
    ]

    const responsiveHeaders = [{ key: 'youtube_url', label: 'Youtube URL' }]

    const onSortChange = (value: string) => {
        setSortBy(value)
        onSort(value)
    }

    const onAddRecord = () => {
        navigate('/orders/new')
    }

    const onGetCollapsibleComponent = (row: any, isCollapsed: boolean) => {
        return (
            <Box>
                {row.bundle_id !== null && (
                    <Box
                        sx={{
                            backgroundColor: '#DEEBFF',
                            color: '#4991FF',
                            padding: '10px 0',
                            textAlign: 'right',
                            fontSize: '14px',
                            paddingRight: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: props.isDesktop ? 'end' : 'center',
                            marginBottom: '10px',
                            img: {
                                marginLeft: '10px',
                            },
                        }}
                    >
                        Bundle ID: {row?.bundle_id ?? ''}
                        <img src={Bundle} alt="bundle" />
                    </Box>
                )}
                {props.isDesktop && (
                    <Box
                        className="details-container"
                        sx={{
                            display: { md: 'flex' },
                            width: '100%',
                            flexDirection: 'row',
                        }}
                    >
                      { row?.platform_id === PLATFORM_YOUTUBE &&
                        <>
                            <div className="video-preview">
                                <YoutubeEmbed embedUrl={row?.youtube_url ?? ''} />
                            </div>
                        </>       
                      }
                        
                        <div className="service-container">
                            <div className="service-details">
                                <div className="detail">
                                    <h5>Service</h5>
                                    <p>
                                        {options?.services[row?.service] ?? ''}
                                        {' - '}
                                        {options?.service_types[
                                            row?.service_type
                                        ] ?? ''}
                                    </p>
                                </div>
                            </div>
                            <div className="service-details">
                                <div className="detail">
                                    <h5>Price</h5>
                                    <p>${row?.amount ?? 0.0}</p>
                                </div>
                                { (row?.service_type ?? 0) === VIEW_TYPE_TARGETED &&
                                    <div className="detail">
                                      <h5>Campaign Report</h5>

                                      {(row?.issues?.length ?? 0) > 0 && (
                                          <DownloadIssue issues={row.issues} />
                                      )}
                                  </div>
                                }
                                
                            </div>
                        </div>
                    </Box>
                )}
                {!props.isDesktop && (
                    <Box sx={{ width: '100%', flexDirection: 'row' }}>
                        {isCollapsed && (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        pb: '10px',
                                        '& iframe': { borderRadius: '10px' },
                                    }}
                                >
                                  { row?.platform_id === PLATFORM_YOUTUBE &&
                                      <>
                                          <YoutubeEmbed
                                              embedUrl={row?.youtube_url ?? ''}
                                          />
                                      </>       
                                    }
                                    
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    pb="10px"
                                >
                                    <div>Service</div>
                                    <Box
                                        sx={{
                                            width: '60%',
                                            position: 'relative',
                                            '& span': {
                                                width: '100%',
                                                overflow: 'hidden',
                                                whiteSpace: 'pre',
                                                textOverflow: 'ellipsis',
                                                position: 'absolute',
                                                left: 0,
                                            },
                                        }}
                                    >
                                        <span>
                                            {options?.services[
                                                row?.service ?? 0
                                            ] ?? ''}
                                        </span>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    pb="10px"
                                >
                                    <div>Price</div>
                                    <div>${row.amount ?? 0.0}</div>
                                </Box>
                            </>
                        )}
                        {headers
                            .filter(
                                (h: any) =>
                                    !responsiveHeaders.find(
                                        (rh) => rh.key === h.key
                                    )
                            )
                            .map((header: any, hIndex: number) => {
                                return (
                                    <Box
                                        key={`collpasible-data-${hIndex}`}
                                        display="flex"
                                        justifyContent="space-between"
                                        pb="10px"
                                    >
                                        <div>{header.label}</div>
                                        <div>
                                            {isLoading ? (
                                                <TextSkeleton width="100px" />
                                            ) : header.customValue ? (
                                                header.customValue(row)
                                            ) : (
                                                row[header.key]
                                            )}
                                        </div>
                                    </Box>
                                )
                            })}
                        {isCollapsed && (
                            <>
                                {(row?.issues?.length ?? 0) > 0 &&  ((row?.service_type ?? 0) === VIEW_TYPE_TARGETED) && (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <div>Campaign Report</div>
                                        <div>
                                            <DownloadIssue
                                                style={{ marginTop: '0px' }}
                                                issues={row.issues}
                                            />
                                        </div>
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>
                )}
            </Box>
        )
    }

    return (
        <TableWrapper
            entityLabel="orders"
            entity="order"
            title="Recent Orders"
            responsiveHeaders={responsiveHeaders}
            headers={headers}
            body={list}
            total={total}
            currentPage={current_page}
            sortBy={sortBy}
            pageCount={last_page}
            pageFrom={from}
            pageTo={to}
            isLoading={isLoading}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onSearch={onSearch}
            onGetCollapsibleComponent={onGetCollapsibleComponent}
            onAddRecord={onAddRecord}
        />
    )
}
