import { useMediaQuery } from '@mui/material'
import moment from 'moment'

export function formatDate(date: string, format?: string) {
    if (!date) return ''
    if (!format) return moment(date).calendar()
    return moment(date).format(format)
}

// To have a uniform responsive display
// To avoid code redundancy
// To edit responsive display in one place
export function getResponsiveDisplayProps(
    screen: string = 'desktop',
    displayDesktop: string = 'block',
    displayMobile: string = 'block'
) {
    return {
        xs: screen === 'desktop' ? 'none' : displayMobile,
        md: screen === 'desktop' ? displayDesktop : 'none',
    }
}

export function IsDesktopScreen(media: string = '(min-width: 900px)') {
    return useMediaQuery(media)
}

export function IsSmallestScreen(media: string = '(max-width: 600px)') {
    return useMediaQuery(media)
}

/**
 * This should work along with onKeyDown event only
 */
export function enforceInt(e: any) {
    //skip enforcement when backspace/delete/arrows < > key
    //allowing to correct the input data
    if ([8, 46, 39, 37].includes(e.keyCode)) {
        return
    }

    if (!/^\d+$/.test(e.key)) {
        e.preventDefault()
    }
}
