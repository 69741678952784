import {
    Avatar,
    Box,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Modal,
} from '@mui/material'
import isEqual from 'lodash/isEqual'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import store, { RootState } from '../store'
import { ScreenState } from '../reducers/screen.reducer'
import OrderForm from '../components/dashboard/order-form.component'
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { OrderAction } from '../actions/orders.action'
import {
    COUNTRY_UK,
    COUNTRY_US,
    COUNTRY_WORLDWIDE,
    IG_SERVICE_FOLLOWERS,
    ORDER_RESET,
    RATE_US_UK,
    RATE_WORLDWIDE,
    RATE_WO_US_UK,
    SERVICE_GROWTH,
    SERVICE_VIEW,
} from '../constants/orders.constants'
import Notification from '../components/notification.components'
import { OrderState } from '../reducers/orders.reducer'
import CustomButton from '../components/customComponents/custom-button.component'
import NewCampaignCarousel from '../components/dashboard/new-campaign-carousel.component'
import NoFundBg from '../assets/images/no-fund-bg.png'
import NoDepositBg from '../assets/images/no-deposit-woman.png'
import DepositFund from '../components/dashboard/deposit-fund.component'
import { OrderService } from '../services/orders.service'
import {
    FOLLOWERS_IG_HIGH,
    VIEW_TYPE_HIGH,
    VIEW_TYPE_TARGETED,
} from '../types/order_status.types'
import { Navigate } from 'react-router'
import Check from '../assets/images/check.png'
import { getCurrentUser } from '../services/auth.service'
import { Tab, TabList, TabPanel, Tabs } from '@mui/joy'
import IgOrderForm from '../components/dashboard/ig-order-form..component'
import { PLATFORM_INSTAGRAM, PLATFORM_YOUTUBE } from '../types/order.types'
import { WalletAction } from '../actions/account.action'

type Options = {
    id: string
    name: string
}

type Delivery = {
    min: number
    max: number
    from: number
    to: number
    is_max: boolean
    service_type: number
}

type Payload = {
    youtube_url?: string
    service_type: number
    service: number
    target_count?: any
    category_id: string
    countries: []
    thumbnails: []
    thumbnail_urls: []
    urls: {}
    is_bundle: boolean
    force?: boolean
}

const formatOptions = (options: Object): Options[] => {
    return (Object.entries(options ?? {}) as [string, string][]).map(
        ([key, value]: [string, string]): Options => {
            return {
                id: key,
                name: value,
            }
        }
    )
}

const validateForm = (payload: any, minMax: any) => {
    let dataFields = [
        {
            key: 'youtube_url',
            isValid: payload.youtube_url !== '',
        },
        {
            key: 'service_type',
            isValid: payload.service_type !== '',
        },
        {
            key: 'target_count',
            isValid:
                payload.target_count * 1 !== 0 &&
                validQuantity(payload.target_count, minMax),
        },
        {
            key: 'service',
            isValid: payload.service !== '',
        },
    ]

    return {
        data: dataFields,
        isValid: dataFields.every((data) => data.isValid),
    }
}

const validateGrowthForm = (payload: any) => {
    let dataFields = [
        {
            key: 'service_type',
            isValid: payload.service_type !== '',
        },
        {
            key: 'service',
            isValid: payload.service !== '',
        },
        {
            key: 'urls',
            isValid: Object.keys(payload.urls).length === 3,
        },
    ]

    return {
        data: dataFields,
        isValid: dataFields.every((data) => data.isValid),
    }
}

const getTotal = (payload: any, countryRates: any, externalService: any) => {
    if (!countryRates) {
        return 0.0
    }

    let targetCount = payload?.target_count ?? 0
    let rate = getRateFromServiceType(payload, countryRates, externalService)

    let total = (targetCount / 1000) * rate
    return parseFloat(total.toFixed(2))
}

const getMinMax = (payload: any, externalService: any) => {
    if (payload?.service_type * 1 === VIEW_TYPE_TARGETED) {
        return {
            min: '10000',
            max: '10000+',
        }
    }

    return {
        min: externalService?.min ?? 0,
        max: externalService?.max ?? 0,
    }
}

const getRateFromServiceType = (
    payload: any,
    countryRates: any,
    externalService: any
) => {
    if (payload?.service_type * 1 === VIEW_TYPE_TARGETED) {
        return getCountryRate(payload?.countries ?? [], countryRates)
    }

    return externalService?.rate ?? 0.0
}

const getCountryRate = (countries: any, countryRates: any) => {
    if (
        countries.length === 0 ||
        (countries.length === 1 && countries[0] === COUNTRY_WORLDWIDE)
    ) {
        return countryRates[RATE_WORLDWIDE] ?? 0.0
    }

    if (
        [COUNTRY_UK, COUNTRY_US].filter((country: string) =>
            countries.includes(country)
        ).length > 0
    ) {
        return countryRates[RATE_US_UK]
    }
    return countryRates[RATE_WO_US_UK]
}

const getAvailableServices = (
    platform: any,
    platformRelation: any,
    services: any
) => {
    if (!platform || !platformRelation || !services) {
        return null
    }

    let relation = platformRelation[platform] ?? null

    if (!relation) {
        return null
    }

    return Object.entries(services)
        .filter((i: any) => relation.includes(i[0] * 1))
        .map((v: any) => ({ id: v[0], name: v[1] }))
}

const getAvailableServiceTypes = (
    service: any,
    serviceRelation: any,
    serviceTypes: any
) => {
    if (!service || !serviceRelation || !serviceTypes) {
        return null
    }

    let relation = serviceRelation[service] ?? null

    if (!relation) {
        return null
    }

    return Object.entries(serviceTypes)
        .filter((i: any) => relation.includes(i[0] * 1))
        .map((v: any) => ({ id: v[0], name: v[1] }))
}

const isBundle = (selectedService: any): boolean => {
    return parseInt(selectedService) === SERVICE_GROWTH
}

const getCompletion = (
    service_type: any,
    target_count: any,
    deliveries: Array<Delivery>
) => {
    service_type = service_type * 1
    target_count = target_count * 1

    const deliveryRange = deliveries.filter(function (delivery) {
        return (
            service_type === delivery.service_type &&
            ((delivery.min <= target_count && delivery.max >= target_count) ||
                (delivery.min <= target_count && delivery.is_max))
        )
    })

    return (
        (deliveryRange.length > 0
            ? deliveryRange[0].from + '-' + deliveryRange[0].to
            : '0') + ' Days'
    )
}

export const validQuantity = (targetCount: number, minMax?: any) => {
    //targetcount 0 or empty
    if (!targetCount) return false

    // targecount less than minimum
    if (targetCount < parseInt(minMax.min)) return false

    //if contains + (should come first cause parseInt(10000+) will be 10000)
    if (typeof minMax.max === 'string' && minMax.max.includes('+')) {
        return true
    }

    // target count
    if (targetCount > parseInt(minMax.max)) return false

    return true
}

const NewOrderScreen = (props: any) => {
    const { options } = useSelector<RootState, ScreenState>(
        (state) => state.screen
    )

    const orderState = useSelector<RootState, OrderState>(
        (state) => state.order
    )
    const isLoading = orderState.loading

    const cacheUser = getCurrentUser()
    const isCustomer = (cacheUser?.roles ?? []).length === 0
    const isVIP = cacheUser?.is_vip ?? false
    const vipInfo = cacheUser?.vip ?? {}

    const [payload, setPayload] = useState<Payload>({
        youtube_url: '',
        service_type: VIEW_TYPE_HIGH,
        service: 1,
        target_count: '',
        category_id: '',
        countries: [],
        thumbnails: [],
        thumbnail_urls: [],
        urls: {},
        is_bundle: false,
    })
    const [openNoFundModal, setOpenNoFundModal] = useState(false)
    const [openNoDepositModal, setOpenNoDepositModal] = useState(false)

    const defaultErrors = {
        youtube_url: false,
        service_type: false,
        service: false,
        target_count: false,
        category_id: true,
        countries: false,
        urls: {
            likes: false,
            subscribers: false,
            views: false,
        },
    }

    const [errors, setErrors] = useState(defaultErrors)

    const [targetCountMinmax, setTargetCountMinMax] = useState({})

    const selectedType = (options?.services ?? {})[payload.service] ?? ''
    const getExternalServiceId = (payload: any, externalServices: any) =>
        OrderService.getExternalServiceIdFromServiceType(
            payload,
            externalServices
        )
    const getTotalPrice = (
        payload: any,
        country_rates: any,
        externalServices: any
    ) => getTotal(payload, country_rates ?? null, externalServices ?? null) ?? 0

    useEffect(() => {
        props.getScreenTitle('Add New Campaign')
    }, []) // eslint-disable-line
    useEffect(() => {
        if (options) {
            const service = localStorage.getItem('service')
            const service_type = localStorage.getItem('service_type')
            const target_count = localStorage.getItem('target_count')

            if (
                service &&
                service_type &&
                target_count &&
                // eslint-disable-next-line eqeqeq
                props?.wallet?.balance != undefined
            ) {
                const newPayload = {
                    ...payload,
                    service: parseInt(service),
                    service_type: parseInt(service_type),
                    target_count: parseInt(target_count),
                }
                setPayload(newPayload)

                const externalServiceId = getExternalServiceId(
                    newPayload?.service_type,
                    options?.external_services
                )

                const totalPrice = getTotalPrice(
                    newPayload,
                    options?.country_rates,
                    options?.external_services[externalServiceId ?? 0]
                )

                if ((props?.wallet?.balance ?? 0) < totalPrice) {
                    if (props.isDesktop) {
                        setOpenNoDepositModal(true)
                    } else {
                        props.getRightNav({
                            title: '',
                            content: (
                                <NoFundComponent
                                    isDesktop={props.isDesktop}
                                    content={
                                        <NoDepositContent
                                            {...props}
                                            selectedType={selectedType}
                                            setOpenNoDepositModal={
                                                setOpenNoDepositModal
                                            }
                                            openNoDepositModal={
                                                openNoDepositModal
                                            }
                                        />
                                    }
                                />
                            ),
                            parentCustomStyle: { padding: 'unset' },
                        })
                        props.showRightSideBar()
                    }
                }
                localStorage.removeItem('service')
                localStorage.removeItem('service_type')
                localStorage.removeItem('target_count')
            }
        }
    }, [options, props]) // eslint-disable-line

    useEffect(() => {
        if (orderState.isBalanceNotEnough) {
            if (props.isDesktop) {
                setOpenNoFundModal(true)
            } else {
                props.getRightNav({
                    title: '',
                    content: (
                        <NoFundComponent
                            isDesktop={props.isDesktop}
                            content={
                                <NoFundContent
                                    {...props}
                                    setOpenNoFundModal={setOpenNoFundModal}
                                    openNoFundModal={openNoFundModal}
                                />
                            }
                        />
                    ),
                    parentCustomStyle: { padding: 'unset' },
                })
            }
            resetOrderState()
        }
        if (orderState.isOrderSuccess) {
            setTimeout(() => {
                store.dispatch(WalletAction.get())
                resetOrderState()
            }, 10000)
        }
    }, [orderState]) // eslint-disable-line

    const resetOrderState = () => {
        store.dispatch({
            type: ORDER_RESET,
        })
    }

    const onSubmit = async (event?: SyntheticEvent) => {
        event?.preventDefault()

        let validation = isBundle(payload.service)
            ? validateGrowthForm(payload)
            : validateForm(payload, targetCountMinmax)

        if (!validation.isValid) {
            setErrors(
                validation.data.reduce((prevField: any, field: any) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultErrors)
            )
            return
        }

        store.dispatch(OrderAction.new(payload))
    }

    useEffect(() => {
        if (!orderState.isUrlDuplicate) {
            setPayload({
                ...payload,
                force: false,
            })
        }
    }, [orderState.isUrlDuplicate]) //eslint-disable-line

    useEffect(() => {
        if (payload.force) {
            onSubmit()
        }
    }, [payload.force]) //eslint-disable-line

    const proceedOrder = () => {
        setPayload({
            ...payload,
            force: true,
        })
    }

    const onChange =
        (name: string, customValue?: any) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = customValue ?? event.target.value
            setPayload((prevState) => {
                let newState: any = {}

                const arrName = name.split('.')
                if (arrName.length > 1) {
                    newState = {
                        ...prevState,
                        [arrName[0]]: {
                            ...prevState.urls,
                            [arrName[1]]: value,
                        },
                    }
                } else {
                    newState = {
                        ...prevState,
                        [name]: value,
                    }
                }

                // Sets Service Type to be the first option everytime Service changes
                if (name === 'service') {
                    newState.is_bundle = isBundle(value)

                    const serviceTypes =
                        getAvailableServiceTypes(
                            value,
                            options?.service_relation,
                            options?.service_types
                        ) || []
                    newState.service_type =
                        serviceTypes.length > 0
                            ? serviceTypes[0]?.id ?? ''
                            : payload.service_type
                }

                return newState
            })
        }

    if (orderState.isUploading && orderState.loading) {
        store.dispatch(OrderAction.upload(payload.thumbnails, orderState.order))
    }

    let externalServiceId = getExternalServiceId(
        payload?.service_type,
        options?.external_services
    )

    let totalPrice =
        getTotalPrice(
            payload,
            options?.country_rates ?? null,
            (options?.external_services ?? [])[externalServiceId ?? 0] ?? null
        ) ?? 0

    let minMax = getMinMax(
        payload,
        (options?.external_services ?? [])[externalServiceId ?? 0] ?? null
    )

    useEffect(() => {
        if (!isEqual(minMax, targetCountMinmax)) {
            setTargetCountMinMax(minMax)
        }
    }, [minMax]) //eslint-disable-line

    const handleTabChange = (value: number) => {
        if (value === 1) {
            setPayload({
                ...payload,
                service: IG_SERVICE_FOLLOWERS,
                service_type: FOLLOWERS_IG_HIGH,
            })
            return
        }

        setPayload({
            ...payload,
            service: SERVICE_VIEW,
            service_type: VIEW_TYPE_HIGH,
        })

        return
    }

    return !isCustomer ? (
        <Navigate to="/" />
    ) : (
        <Box className="new-order">
            <Container className="container">
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        <Tabs
                            className="tabs-container"
                            aria-label="Basic tabs"
                            defaultValue={0}
                            sx={{ borderRadius: 'lg' }}
                            onChange={(event, value) =>
                                handleTabChange(value as number)
                            }
                        >
                            {isVIP && (
                                <TabList>
                                    <Tab>Youtube Services</Tab>
                                    <Tab>Instagram Services</Tab>
                                </TabList>
                            )}

                            <TabPanel value={0} sx={{ p: 2 }}>
                                <OrderForm
                                    options={{
                                        countries: formatOptions(
                                            options?.countries
                                        ),
                                        categories: formatOptions(
                                            options?.categories
                                        ),
                                        services: getAvailableServices(
                                            PLATFORM_YOUTUBE,
                                            options?.platform_relation,
                                            options?.services
                                        ),
                                        serviceTypes: getAvailableServiceTypes(
                                            payload?.service,
                                            options?.service_relation,
                                            options?.service_types
                                        ),
                                        bundles: options?.bundles,
                                        allServiceTypes: options?.service_types,
                                    }}
                                    formProps={{ onSubmit: onSubmit }}
                                    onChange={onChange}
                                    errors={errors}
                                    loading={orderState.loading}
                                    isDesktop={props.isDesktop}
                                    totalPrice={totalPrice}
                                    payload={payload}
                                    minMax={minMax}
                                    validQuantity={validQuantity}
                                />
                            </TabPanel>

                            {isVIP && (
                                <TabPanel value={1} sx={{ p: 2 }}>
                                    <IgOrderForm
                                        options={{
                                            countries: formatOptions(
                                                options?.countries
                                            ),
                                            categories: formatOptions(
                                                options?.categories
                                            ),
                                            services: getAvailableServices(
                                                PLATFORM_INSTAGRAM,
                                                options?.platform_relation,
                                                options?.services
                                            ),
                                            serviceTypes:
                                                getAvailableServiceTypes(
                                                    payload?.service,
                                                    options?.service_relation,
                                                    options?.service_types
                                                ),
                                            bundles: options?.bundles,
                                            allServiceTypes:
                                                options?.service_types,
                                        }}
                                        formProps={{ onSubmit: onSubmit }}
                                        onChange={onChange}
                                        errors={errors}
                                        loading={orderState.loading}
                                        isDesktop={props.isDesktop}
                                        totalPrice={totalPrice}
                                        payload={payload}
                                        minMax={minMax}
                                        validQuantity={validQuantity}
                                    />
                                </TabPanel>
                            )}
                        </Tabs>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <div className="order-summary">
                            <h4>Order Summary</h4>
                            <Divider />
                            <List>
                                <ListItem alignItems="flex-start">
                                    <ListItemText primary="Service" />
                                    <ListItemText
                                        secondary={
                                            options?.services[
                                                payload?.service ?? 0
                                            ] ?? ''
                                        }
                                    />
                                </ListItem>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={
                                            isBundle(payload.service)
                                                ? 'Package Selected'
                                                : 'Service Type'
                                        }
                                    />
                                    <ListItemText
                                        secondary={
                                            options?.service_types[
                                                payload?.service_type ?? 0
                                            ] ?? ''
                                        }
                                    />
                                </ListItem>

                                {!isBundle(payload.service) && (
                                    <>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText primary="Quantity" />
                                            <ListItemText
                                                secondary={
                                                    payload.target_count ?? 0
                                                }
                                            />
                                        </ListItem>

                                        <ListItem alignItems="flex-start">
                                            <ListItemText primary="Expected time of completion" />
                                            <ListItemText
                                                secondary={getCompletion(
                                                    payload.service_type,
                                                    payload.target_count,
                                                    options?.deliveries ?? []
                                                )}
                                            />
                                        </ListItem>

                                        <Divider />
                                        <ListItem alignItems="flex-start">
                                            <ListItemText primary="Total" />
                                            <ListItemText
                                                className="total"
                                                secondary={`$` + totalPrice}
                                            />
                                        </ListItem>
                                    </>
                                )}

                                {isBundle(payload.service) && (
                                    <>
                                        <Box
                                            sx={{
                                                backgroundColor: '#FFFBFB',
                                                borderRadius: '5px',
                                                padding: '12px',
                                                marginBottom: '20px',
                                                '& p.title': {
                                                    fontSize: '12px',
                                                    margin: 0,
                                                    marginBottom: '15px',
                                                    opacity: '0.5',
                                                },
                                                '& img': {
                                                    height: '15px',
                                                    width: '15px',
                                                    marginRight: '10px',
                                                },
                                                '& div.services': {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    marginBottom: '15px',
                                                    marginLeft: '5px',
                                                    '& p': {
                                                        margin: '0',
                                                        fontSize: '14px',
                                                    },
                                                },
                                            }}
                                        >
                                            <p className="title">
                                                {
                                                    options.service_types[
                                                        payload.service_type
                                                    ]
                                                }{' '}
                                                Inclusion:
                                            </p>
                                            <div className="services-container">
                                                {options.bundles &&
                                                    options.bundles[
                                                        payload.service_type
                                                    ].bundle.map(
                                                        (
                                                            bundle: any,
                                                            index: number
                                                        ) => (
                                                            <div
                                                                className="services"
                                                                key={
                                                                    `k_` + index
                                                                }
                                                            >
                                                                <img
                                                                    src={Check}
                                                                    alt=""
                                                                />{' '}
                                                                <p>
                                                                    <b>
                                                                        {bundle?.target_count.toLocaleString() ??
                                                                            ''}
                                                                    </b>{' '}
                                                                    {
                                                                        options
                                                                            .service_types[
                                                                            bundle?.service_type ??
                                                                                0
                                                                        ]
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </Box>

                                        <ListItem alignItems="flex-start">
                                            <ListItemText primary="Expected time of completion" />
                                            <ListItemText
                                                secondary={
                                                    (options.bundles[
                                                        payload.service_type ??
                                                            0
                                                    ]?.delivery?.from ?? '') +
                                                    `-` +
                                                    (options?.bundles[
                                                        payload.service_type ??
                                                            0
                                                    ]?.delivery?.to ?? '') +
                                                    ` Days`
                                                }
                                            />
                                        </ListItem>

                                        <Divider />
                                        <ListItem alignItems="flex-start">
                                            <ListItemText primary="Total" />
                                            <ListItemText
                                                className="total"
                                                secondary={
                                                    `$` +
                                                        options.bundles[
                                                            payload.service_type ??
                                                                0
                                                        ]?.rate ?? ''
                                                }
                                            />
                                        </ListItem>
                                    </>
                                )}
                            </List>
                        </div>
                        <div className="testimony">
                            <NewCampaignCarousel
                                isVIP={isVIP}
                                vipSettings={vipInfo?.settings ?? {}}
                            />
                        </div>
                        {!props.isDesktop && (
                            <Box
                                sx={{
                                    bgcolor: '#fff',
                                    height: '150px',
                                    position: 'fixed',
                                    bottom: 0,
                                    width: '100%',
                                    left: 0,
                                    borderTopLeftRadius: '40px',
                                    borderTopRightRadius: '40px',
                                    boxShadow:
                                        '0px 0px 5px 0px rgb(0 0 0 / 10%)',
                                    textAlign: 'center',
                                    zIndex: '999',
                                }}
                            >
                                <CustomButton
                                    isLoading={isLoading}
                                    label="Start Campaign"
                                    type="submit"
                                    className="upviews-button"
                                    sx={{
                                        float: 'unset!important',
                                        mr: 'unset!important',
                                        mt: '17px!important',
                                        width: '90%',
                                    }}
                                    onClick={onSubmit}
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Notification
                    message={
                        orderState.data?.message ??
                        'Creating order error has occurred.'
                    }
                    withAction={orderState.isUrlDuplicate}
                    open={orderState.showNotification}
                    severity={orderState.hasError ? 'error' : 'success'}
                    onClose={(reason: any) => {
                        resetOrderState()
                        if (reason === 'proceed') {
                            proceedOrder()
                        }
                    }}
                />
                <Modal open={openNoFundModal}>
                    <NoFundComponent
                        isDesktop={props.isDesktop}
                        content={
                            <NoFundContent
                                {...props}
                                setOpenNoFundModal={setOpenNoFundModal}
                                openNoFundModal={openNoFundModal}
                            />
                        }
                    />
                </Modal>
                <Modal open={openNoDepositModal}>
                    <NoFundComponent
                        isDesktop={props.isDesktop}
                        content={
                            <NoDepositContent
                                {...props}
                                selectedType={selectedType}
                                setOpenNoDepositModal={setOpenNoDepositModal}
                                openNoDepositModal={openNoDepositModal}
                            />
                        }
                    />
                </Modal>
            </Container>
        </Box>
    )
}

const NoFundContent = (props: any) => (
    <Box display="flex">
        <div className="nf-container">
            {props.isDesktop && <img src={NoFundBg} alt="" />}
            <div className="description no-padding">
                <div>
                    <h6>Uh Oh! Not Enough Funds in Balance.</h6>
                    <p>
                        No Worries, make a deposit now and get the marketing
                        started!
                    </p>
                    <CustomButton
                        label="Deposit Funds"
                        className="upviews-button"
                        onClick={() => {
                            if (props.isDesktop) {
                                props.openTrxModal(true)
                                return
                            }
                            props.getRightNav({
                                title: 'Deposit Funds',
                                content: (
                                    <DepositFund
                                        onClose={props.showRightSideBar}
                                        isDesktop={props.isDesktop}
                                        transaction={props.transaction}
                                    />
                                ),
                            })
                            props.setOpenNoFundModal(false)
                        }}
                        sx={{
                            mb: '30px!important',
                            paddingLeft: '60px!important',
                            paddingRight: '60px!important',
                            width: !props.isDesktop ? '100%' : 'unset',
                        }}
                    />
                </div>
            </div>
            {!props.isDesktop && <img src={NoFundBg} alt="" />}
        </div>
        {props.isDesktop && (
            <Box
                sx={{
                    position: 'relative',
                    height: '100px',
                    right: '35px',
                    top: '125px',
                }}
            >
                <Avatar
                    onClick={() =>
                        props.setOpenNoFundModal(!props.openNoFundModal)
                    }
                    sx={{
                        bgcolor: '#F8451B',
                    }}
                >
                    <CloseIcon />
                </Avatar>
            </Box>
        )}
    </Box>
)

const NoDepositContent = (props: any) => (
    <Box display="flex">
        <div className="nf-container">
            {props.isDesktop && <img src={NoDepositBg} alt="" />}
            <div className="description">
                <div>
                    <h6>
                        Looks like you’re trying to Start A New Campaign with
                        our {props.selectedType} Services Package
                    </h6>
                    <p>
                        In order to start the campaign, you need to deposit
                        funds in your wallet first. No Worries, you can make a
                        deposit now and get the marketing started!
                    </p>
                    <CustomButton
                        label="Deposit Funds Now"
                        className="upviews-button"
                        onClick={() => {
                            if (props.isDesktop) {
                                props.openTrxModal(true)
                                return
                            }
                            props.getRightNav({
                                title: 'Deposit Funds',
                                content: (
                                    <DepositFund
                                        onClose={props.showRightSideBar}
                                        isDesktop={props.isDesktop}
                                        transaction={props.transaction}
                                    />
                                ),
                            })
                            props.setOpenNoDepositModal(false)
                        }}
                        sx={{
                            mb: '30px!important',
                            paddingLeft: '60px!important',
                            paddingRight: '60px!important',
                            width: !props.isDesktop ? '100%' : 'unset',
                        }}
                    />
                </div>
            </div>
            {!props.isDesktop && <img src={NoDepositBg} alt="" />}
        </div>
        {props.isDesktop && (
            <Box
                sx={{
                    position: 'relative',
                    height: '100px',
                    right: '55px',
                    top: '125px',
                }}
            >
                <Avatar
                    onClick={() =>
                        props.setOpenNoDepositModal(!props.openNoDepositModal)
                    }
                    sx={{
                        bgcolor: '#F8451B',
                    }}
                >
                    <CloseIcon />
                </Avatar>
            </Box>
        )}
    </Box>
)

const NoFundComponent = React.forwardRef((props: any, ref) => (
    <Box
        sx={{
            outline: 'none',
            position: props.isDesktop ? ('absolute' as 'absolute') : 'unset',
            top: '50%',
            left: '50%',
            transform: props.isDesktop ? 'translate(-50%, -50%)' : 'unset',
            bgcolor: 'transparent',
            '& .nf-container': {
                display: 'flex',
                flexDirection: 'column',
                '& .description': {
                    maxWidth: '650px',
                    padding: '0px  4%!important',
                    '&.no-padding': {
                        padding: '0px 0px!important',
                    },
                    '&>div': {
                        bgcolor: 'background.paper',
                        borderBottomLeftRadius: '35px',
                        borderBottomRightRadius: '35px',
                        textAlign: 'center',
                        padding: '20px 30px 0px 30px',
                        '& h6': {
                            fontSize: '24px',
                            marginTop: '0px',
                            marginBottom: '20px',
                        },
                        '& p': {
                            fontSize: '18px',
                        },
                    },
                },
                img: {
                    width: !props.isDesktop ? '100%' : 'unset',
                },
            },
        }}
    >
        {props.content}
    </Box>
))

export default NewOrderScreen
