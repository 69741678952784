import { Divider } from '@mui/material'
import { Box } from '@mui/system'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import YoutubeEmbed from './youtube-embed.component'
import DownloadIssue from './customer/download-issue.component'
import { OrderService } from '../../services/orders.service'
import Bundle from '../../assets/images/bundle-blue.png'
import { PLATFORM_INSTAGRAM, PLATFORM_YOUTUBE } from '../../types/order.types'
import { VIEW_TYPE_TARGETED } from '../../types/order_status.types'

export default function ViewCampaign(props: any) {
    return (
        <Box
            sx={{
                '.video-preview': {
                    iframe: {
                        borderRadius: '10px',
                        width: '100%',
                    },
                },

                h6: {
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: '#6B6F7B',
                    margin: 0,
                    marginBottom: '12px',
                    mt: '10px',

                    '&:first-of-type': { mt: 0 },
                },

                p: {
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: '#2E2E2F',
                    margin: 0,
                    marginBottom: '15px',

                    '& .download': {
                        color: '#27A857',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        svg: { paddingRight: '5px' },
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },

                    '& .status': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',

                        svg: { paddingRight: '5px' },
                        '&.Completed': { color: '#34E692' },
                        '&.Refunded': { color: '#FF9548' },
                        '&.Processing': { color: '#48BDFF' },
                        '&.Progress': { color: '#48BDFF' },
                        '&.Pending': { color: '#C9C9C9' },
                    },
                },
                '& .youtube_url': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                },
            }}
        >
            {
                  props.record?.bundle_id !== null &&
                      <Box sx={{
                        backgroundColor: '#DEEBFF',
                        color: '#4991FF',
                        padding: '10px 0',
                        textAlign: 'right',
                        fontSize: '14px',
                        paddingRight: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: props.isDesktop ? 'end' : 'center',
                        marginBottom: '10px',
                        img: {
                          marginLeft: '10px',
                        }
                      }}
                      >
                        Bundle ID: {props.record?.bundle_id ?? ''}
                        <img src={Bundle} alt="bundle" />
                      </Box>
                }

            { props.record?.platform_id === PLATFORM_YOUTUBE &&
              <>
                  <h6> Youtube URL</h6>
                  <p className="youtube_url">{props.record?.youtube_url ?? ''}</p>
                  <div className="video-preview">
                      <YoutubeEmbed embedUrl={props.record?.youtube_url ?? ''} />
                  </div>
              </>       
            }

            { props.record?.platform_id === PLATFORM_INSTAGRAM &&
              <>
                  <h6> Instagram URL</h6>
                  <p className="youtube_url">{props.record?.youtube_url ?? ''}</p>
              </>       
            }
            
            <Divider />

            <h6>Service</h6>
            <p>{props.options?.services[props.record?.service ?? 0] ?? ''}</p>
            <Divider />

            <h6>Service Type</h6>
            <p>
                {props.options?.service_types[
                    props.record?.service_type ?? 0
                ] ?? ''}
            </p>
            <Divider />

            <h6>Wanted</h6>
            <p>{props.record?.target_count ?? 0} {OrderService.getTargetSubject(props.record?.service)}</p>
            <Divider />

            <h6>Start</h6>
            <p>
                {props.record?.start_count ?? 0}{' '}
                {OrderService.getTargetSubject(props.record?.service)}
            </p>
            <Divider />
            <h6>Remaining</h6>
            <p>{props.record?.remains ?? 0} {OrderService.getTargetSubject(props.record?.service)}</p>
            <Divider />

            <h6>Status</h6>
            <p
                className={`status ${
                    (props.options?.statuses ?? {})[props.record?.status] ?? ''
                }`}
            >
                <CircleOutlinedIcon />{' '}
                {props.options?.statuses[props.record?.status ?? 0] ?? ''}
            </p>
            <Divider />

            { (props.record?.service_type ?? 0) === VIEW_TYPE_TARGETED &&
                <>
                  <h6>Campaign Report</h6>
                  {(props.record?.issues?.length ?? 0) > 0 && (
                      <DownloadIssue issues={props.record?.issues ?? []} />
                  )}
                </>
            }
            
        </Box>
    )
}
