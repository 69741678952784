import { INSTAGRAM_FAIL, INSTAGRAM_REQUEST, INSTAGRAM_SUCCESS } from "../constants/instagram.constants";
import { InstagramService } from "../services/instagram.service";
import { AppThunk } from "../store";

export const InstagramAction = {
    get: (instagramUser: string): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: INSTAGRAM_REQUEST,
      });

      await InstagramService.get(instagramUser)
        .then((response: any) => {
          let responseData = response?.data;
         
          if (responseData?.data) {
            return dispatch({
              type: INSTAGRAM_SUCCESS,
              payload: responseData.data,
            });
          }

          return response?.data;
        })
        .catch(function (error) {
          dispatch({
            type:  INSTAGRAM_FAIL,
            payload: error?.response?.data,
          });
        });
    },
}