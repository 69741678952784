export const CREDIT_DEBIT = 1
export const BANK_TRANSFER = 2
export const CRYPTO = 3

export const PAYMENT_TYPE: any = {
    1: 'Credit/Debit',
    2: 'Bank Transfer',
    3: 'Crypto',
}

export const TRANSACTION_TYPE: any = {
    1: 'Deposit',
    2: 'Withdraw',
}

export const PAYMENT_STATUS: any = {
    confirmed: { label: 'Payment Accepted', color: '#34E692' },
    pending: { label: 'Payment Pending', color: '#FF9548' },
    invalid: { label: 'Payment Rejected', color: '#FF4848' },
    cancelled: { label: 'Payment Cancelled', color: '#FF4848' },
    manual_review: { label: 'Manual Review', color: '#F7684a' },
}
