import { Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DepositFund from '../deposit-fund.component'
import CustomButton from '../../customComponents/custom-button.component'

const FundsComponent = (props: any) => {
    const { getRightNav, showRightSideBar, isDesktop, transaction } = props
    const {
        wallet = {
            balance: 0.0,
            spending: 0.0,
        },
    } = props

    const handleOpenDepositFundDialog = () => {
        if (isDesktop) {
            showRightSideBar()
            props.openTrxModal(true)
            return
        }
        getRightNav({
            title: 'Deposit Funds',
            content: (
                <DepositFund
                    onClose={showRightSideBar}
                    isDesktop={isDesktop}
                    transaction={transaction}
                />
            ),
        })
    }

    return (
        <Box
            sx={{
                '.label': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: '30px',
                    h3: {
                        fontWeight: '700',
                        fontSize: '20px',
                        margin: 0,
                        mr: '20px',
                    },
                    '& .MuiButton-root': {
                        bgcolor: ' #27A857',
                        borderRadius: '30px',
                        fontWeight: 700,
                        fontSize: '16px',
                        boxShadow: 'none',
                        padding: '10px 20px',
                        color: '#fff',
                        width: '100%',
                        svg: { color: '#fff !important' },
                        '&:hover': {
                            boxShadow: 'none',
                            bgcolor: ' #27A857',
                        },
                    },
                },
                '.label2': {
                    padding: '0 20px',
                    mt: '30px',
                    mb: '40px',
                    h3: {
                        fontWeight: 700,
                        fontSize: '20px',
                        margin: 0,
                        mr: '20px',
                    },
                },
                '.funds-container': {
                    '.boxes': {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '.box': {
                            alignSelf: 'stretch',
                            flexGrow: 1,
                            flexShrink: 1,
                            flexBasis: 0,
                            textAlign: 'center',
                            background: '#FFFFFF',
                            boxShadow:
                                '0px 40px 60px rgba(128, 144, 155, 0.25)',
                            borderRadius: '16px',
                            padding: '15px 20px',
                            '&:first-of-type': {
                                mr: '30px',
                            },
                            h4: {
                                fontWeight: 700,
                                fontSize: '22px',
                                margin: 0,
                                color: '#27A857',
                            },
                            p: {
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '25px',
                                margin: 0,
                                color: '#595959',
                            },
                        },
                    },
                },
                '.updates-container': {
                    '.update-list': {
                        padding: '10px 10px',
                        mb: '15px',
                        background: '#FFFFFF',
                        boxShadow: '0px 20px 60px rgba(128, 144, 155, 0.2)',
                        borderRadius: '14px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '.service': {
                            h4: {
                                textAlign: 'left',
                                margin: 0,
                                fontWeight: 700,
                                fontSize: '14px',
                                color: '#333333',
                            },
                            p: {
                                margin: 0,
                                fontWeight: 400,
                                fontSize: '12px',
                                color: '#8A97A4',
                            },
                        },
                        '.time': {
                            flexGrow: 1,
                            textAlign: 'right',
                            margin: 0,
                            fontWeight: 400,
                            fontSize: '12px',
                            color: '#8998A9',
                        },
                    },
                },
            }}
        >
            <div className="funds-container">
                <div className="boxes">
                    <div className="box">
                        <h4>${wallet?.balance?.toFixed(2) ?? 0}</h4>
                        <p>Balance</p>
                    </div>
                    <div className="box">
                        <h4>${wallet?.spending?.toFixed(2) ?? 0}</h4>
                        <p>Lifetime Spend</p>
                    </div>
                </div>
            </div>
            <div className="label">
                <CustomButton
                    label="Deposit Funds"
                    className="upviews-button"
                    onClick={handleOpenDepositFundDialog}
                    endIcon={<AddIcon />}
                    width="unset"
                />
            </div>
            {/* <div className="label2">
                <h3>News and Updates</h3>
            </div>

            <div className="updates-container">
                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>
                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>

                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>
                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>
                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>
                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>
                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>
                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>
                <div className="update-list">
                    <div className="service item">
                        <h4>Youtube Likes 100</h4>
                        <p>Instant start 100-300/Day</p>
                    </div>
                    <p className="time item">2 days ago</p>
                </div>
            </div> */}
        </Box>
    )
}

export default FundsComponent
