import { ChangeEvent, SyntheticEvent, useState } from 'react'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import SendIcon from '@mui/icons-material/Send'
import CancelIcon from '@mui/icons-material/Cancel'
import { Box } from '@mui/system'
import { Button, Chip, FormControl, MenuItem, Select } from '@mui/material'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import DownloadIcon from '@mui/icons-material/Download'
import ArrowOutwardIcon from '@mui/icons-material/CallMade'

import { useSelector } from 'react-redux'
import { formatDate, IsSmallestScreen } from '../../../helpers/utils.helper'
import TableWrapper from '../../wrappers/table-wrapper.component'
import YoutubeEmbed from '../youtube-embed.component'
import store, { RootState } from '../../../store'
import { ScreenState } from '../../../reducers/screen.reducer'
import { UserState } from '../../../reducers/user.reducer'
import { TYPE_WORKER } from '../../../types/user.types'
import OrderActionsForm from '../worker/order-actions-form.component'
import { OrderAction } from '../../../actions/orders.action'
import { OrderState } from '../../../reducers/orders.reducer'
import TextSkeleton from '../../skeletons/text.skeleton'
import {
    SERVICE_TYPES,
    VIEW_TYPE_TARGETED,
} from '../../../types/order_status.types'
import DownloadIssue from '../customer/download-issue.component'
import { OrderService } from '../../../services/orders.service'
import { PAYMENT_TYPE } from '../../../types/transaction_options.types'
import {
    getBankName,
    getLast4,
    getReference,
} from '../../../services/deposit.service'

import Bundle from '../../../assets/images/bundle-blue.png'
import CustomStatus from '../../customComponents/custom-status.component'
import { KeyboardArrowDown } from '@mui/icons-material'

type Options = {
    id: string
    name: string
}

const formatOptions = (options: Object): Options[] => {
    return (Object.entries(options ?? {}) as [string, string][]).map(
        ([key, value]: [string, string]): Options => {
            return {
                id: key,
                name: value,
            }
        }
    )
}

export default function AdminOrders(props: any) {
    const {
        onSearch,
        list,
        meta,
        onSort,
        onChangeStatus,
        onPageChange,
        cancelOrder,
        resendOrder,
        isLoading,
        isDesktop,
    } = props

    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}
    let { options } = useSelector<RootState, ScreenState>(
        (state) => state.screen
    )
    const isSmallestScreen = IsSmallestScreen()
    const user = useSelector<RootState, UserState>((state) => state.userLogin)
    const orderState = useSelector<RootState, OrderState>(
        (state) => state.order
    )
    const roles = user?.info?.roles ?? []
    const isWorker = roles.includes(TYPE_WORKER)
    options = options ?? {}
    const serviceTypesEntries = Object.entries(SERVICE_TYPES)

    const [sortBy, setSortBy] = useState('')
    const [filteredStatus, setFilteredStatus] = useState('')
    const [seeMore, setSeeMore] = useState('none')

    const [actionPayload, setActionPayload] = useState({
        action: '',
        status: '',
        issues: [],
        orderId: 0,
    })

    const onSubmit = async (event: SyntheticEvent, orderId: number) => {
        event.preventDefault()
        setActionPayload({
            ...actionPayload,
            orderId: orderId,
        })
        setTimeout(function () {
            store.dispatch(OrderAction.action(orderId, actionPayload))
        })
    }

    const onChange =
        (name: any, customValue?: any) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            if (typeof name === 'object') {
                if ((name.issues?.length ?? 0) > 0) {
                    store.dispatch(
                        OrderAction.actionUpload(name.issues, name.orderId)
                    )
                }
                setActionPayload({ ...actionPayload, ...name })
                return
            }

            const value = customValue ?? event.target.value

            setActionPayload({
                ...actionPayload,
                [name]: value,
            })
        }

    const headers = [
        { key: 'code', label: 'Order No.' },
        { key: 'youtube_url', label: 'Youtube URL' },
        {
            key: 'amount',
            label: 'Price',
            customValue: (row: any) => {
                return `$${row.amount}`
            },
        },
        {
            key: 'service_type',
            label: 'Service Type',
            customValue: (row: any) => {
                return (
                    (serviceTypesEntries.find(
                        (e) => e[0] === `${row.service_type}`
                    ) ?? [])[1] ?? ''
                )
            },
        },
        {
            key: 'current_views',
            label: 'Current',
            customValue: (row: any) =>
                `${row.current_views} ${OrderService.getTargetSubject(
                    row.service
                )}`,
        },
        {
            key: 'remaining',
            label: 'Remaining',
            customValue: (row: any) =>
                `${row.remains} ${OrderService.getTargetSubject(row.service)}`,
        },
        {
            key: 'status',
            label: 'Status',
            customValue: (row: any) => {
                const status = row?.status || 'pending'
                return <CustomStatus status={status} />
            },
        },
    ]

    const responsiveHeaders = [{ key: 'youtube_url', label: 'Youtube URL' }]

    const contextMenu = [
        {
            callBack: () => {},
            label: 'Refund Order',
            icon: <CurrencyExchangeIcon />,
        },
        {
            callBack: (row: any) => resendOrder(row?.id),
            label: 'Resend Order to API',
            icon: <SendIcon />,
        },
        {
            callBack: (row: any) => cancelOrder(row?.id),
            label: 'Cancel Order',
            icon: <CancelIcon />,
        },
    ]

    const onSortChange = (value: string) => {
        setSortBy(value)
        onSort(value)
    }

    const onStatusChange = (value: string) => {
        setFilteredStatus(value)
        onChangeStatus(value)
    }

    const showMoreCountries = () => setSeeMore('')
    const hideMoreCountries = () => setSeeMore('none')

    const onGetCollapsibleComponent = (
        row: any,
        isCollapsed: boolean = false
    ) => {
        const filteredHeaders = headers.filter((header: any) =>
            responsiveHeaders.find((r) => r.key !== header.key)
        )

        return (
            <Box sx={{ margin: 0 }} key={row.id}>
                {row.bundle_id !== null && (
                    <Box
                        sx={{
                            backgroundColor: '#DEEBFF',
                            color: '#4991FF',
                            padding: '10px 0',
                            textAlign: 'right',
                            fontSize: '14px',
                            paddingRight: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: props.isDesktop ? 'end' : 'center',
                            marginBottom: '10px',
                            img: {
                                marginLeft: '10px',
                            },
                        }}
                    >
                        Bundle ID: {row?.bundle_id ?? ''}
                        <img src={Bundle} alt="bundle" />
                    </Box>
                )}
                <Box
                    className="admin-details-container"
                    display={isDesktop ? 'flex' : 'block!important'}
                >
                    <Box
                        className="video-preview"
                        sx={
                            isDesktop
                                ? {}
                                : {
                                      display: !isCollapsed ? 'none' : 'flex',
                                      justifyContent: 'center',
                                      pb: '10px',
                                      width: 'unset!important',
                                  }
                        }
                    >
                        <YoutubeEmbed embedUrl={row?.youtube_url ?? ''} />
                    </Box>
                    {isDesktop ? (
                        <div className="order-information">
                            <p className="title">Order Information</p>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div style={{ width: '50%' }}>
                                    {row?.service_type !==
                                        VIEW_TYPE_TARGETED && (
                                        <div className="information-detail-column">
                                            <div className="labeled-desc width-60">
                                                <p>External ID</p>
                                                <p>
                                                    {isLoading ? (
                                                        <TextSkeleton />
                                                    ) : (
                                                        row?.external_order_id
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <div className="information-detail-column">
                                        <div className="labeled-desc width-60">
                                            <p>Date &amp; Time Ordered</p>
                                            <p>
                                                {isLoading ? (
                                                    <TextSkeleton />
                                                ) : (
                                                    formatDate(
                                                        row?.created_at ?? ''
                                                    )
                                                )}
                                            </p>
                                        </div>
                                        <div className="labeled-desc width-40">
                                            <p>Price</p>
                                            <p>
                                                {isLoading ? (
                                                    <TextSkeleton />
                                                ) : (
                                                    `$${row?.amount ?? 0}`
                                                )}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="information-detail-column">
                                        <div className="labeled-desc width-60">
                                            <p>
                                                Amount of ordered{' '}
                                                {OrderService.getTargetSubject(
                                                    row?.service
                                                )}
                                            </p>
                                            <p>
                                                {isLoading ? (
                                                    <TextSkeleton />
                                                ) : (
                                                    `${
                                                        row?.target_count ?? 0
                                                    } ` +
                                                    OrderService.getTargetSubject(
                                                        row?.service
                                                    )
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="information-detail-column">
                                        <div className="labeled-desc width-100">
                                            <p>Service Selected</p>
                                            <p>
                                                {isLoading ? (
                                                    <TextSkeleton />
                                                ) : (
                                                    `${
                                                        (options?.services ??
                                                            [])[row?.service] ??
                                                        ''
                                                    } - ${
                                                        (options?.service_types ??
                                                            [])[
                                                            row?.service_type
                                                        ] ?? ''
                                                    }`
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {!isWorker && (
                                    <div style={{ width: '50%' }}>
                                        <div className="information-detail-column">
                                            <div className="labeled-desc width-60">
                                                <p>Latest Reference ID</p>
                                                <p>
                                                    {isLoading ? (
                                                        <TextSkeleton />
                                                    ) : (
                                                        getReference(row)
                                                    )}
                                                </p>
                                            </div>
                                            <div className="labeled-desc width-40">
                                                <p>Card Type</p>
                                                <p>
                                                    {isLoading ? (
                                                        <TextSkeleton />
                                                    ) : (
                                                        PAYMENT_TYPE[
                                                            row?.transaction
                                                                ?.option ?? 0
                                                        ] ?? ''
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="information-detail-column">
                                            <div className="labeled-desc width-60">
                                                <p>Bank Name</p>
                                                <p>
                                                    {isLoading ? (
                                                        <TextSkeleton />
                                                    ) : (
                                                        getBankName(row)
                                                    )}
                                                </p>
                                            </div>
                                            <div className="labeled-desc width-40">
                                                <p>Last 4 No. of Account</p>
                                                <p>
                                                    {isLoading ? (
                                                        <TextSkeleton />
                                                    ) : (
                                                        getLast4(row)
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="information-detail-column">
                                            <div
                                                className="labeled-desc width-60"
                                                style={{ display: 'none' }}
                                            >
                                                <p>Email Address</p>
                                                <p>
                                                    {isLoading ? (
                                                        <TextSkeleton />
                                                    ) : (
                                                        ''
                                                    )}
                                                </p>
                                            </div>
                                            <div className="labeled-desc width-40">
                                                <p>Account Transactions</p>
                                                {isLoading ? (
                                                    <TextSkeleton />
                                                ) : (
                                                    <Box
                                                        onClick={() =>
                                                            window.open(
                                                                `/deposits?userId=${row.user_id}`
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: 'pointer',
                                                            color: '#27A857',
                                                            textDecoration:
                                                                'underline',
                                                            display: 'flex',
                                                            '& span': {
                                                                mr: '5px',
                                                            },
                                                        }}
                                                    >
                                                        <span>
                                                            Check all
                                                            transactions
                                                        </span>
                                                        <ArrowOutwardIcon fontSize="small" />
                                                    </Box>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Box>
                            {row.service_type === VIEW_TYPE_TARGETED && (
                                <>
                                    <hr />
                                    <p className="title">Targeting Options</p>

                                    <div className="information-detail-column">
                                        <div className="labeled-desc">
                                            <p>Categories</p>
                                            <div className="chips-container">
                                                {(row?.category_id ?? null) !==
                                                    null && (
                                                    <Chip
                                                        label={
                                                            (options?.categories ??
                                                                {})[
                                                                row?.category_id ??
                                                                    0
                                                            ] ?? ''
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="labeled-desc">
                                            <p>Countries</p>
                                            <div className="chips-container">
                                                {(row?.countries ?? false) &&
                                                    row.countries.map(
                                                        (
                                                            country: any,
                                                            index: number
                                                        ) => (
                                                            <Chip
                                                                key={`desktop-chip-${index}-${row.id}`}
                                                                label={
                                                                    (options?.countries ??
                                                                        {})[
                                                                        country
                                                                    ] ?? ''
                                                                }
                                                                sx={{
                                                                    display:
                                                                        index >
                                                                        4
                                                                            ? seeMore
                                                                            : '',
                                                                }}
                                                            />
                                                        )
                                                    )}
                                            </div>
                                            {(row?.countries?.length ?? 0) >
                                                5 &&
                                                seeMore === 'none' && (
                                                    <Chip
                                                        className="see-more"
                                                        label="See all "
                                                        onClick={
                                                            showMoreCountries
                                                        }
                                                    />
                                                )}

                                            {(row?.countries?.length ?? 0) >
                                                5 &&
                                                seeMore === '' && (
                                                    <Chip
                                                        className="see-more"
                                                        label="Hide some"
                                                        onClick={
                                                            hideMoreCountries
                                                        }
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {  isCollapsed && (row?.issues?.length ?? 0) > 0 && (
                                    <>
                                        <hr />
                                        <p className="title">Campaign Report</p>
                                        <Box
                                            sx={{
                                                '& .issue': {
                                                    color: '#27A857',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                    },
                                                    svg: {
                                                        paddingRight: '5px',
                                                    },
                                                    mr: '20px',
                                                },
                                            }}
                                            display="flex"
                                        >
                                            <DownloadIssue
                                                issues={row.issues}
                                            />
                                        </Box>
                                    </>
                                )}
                        </div>
                    ) : (
                        <Box
                            sx={{
                                '& span.info': {
                                    color: '#656565',
                                    mt: '100px',
                                },
                            }}
                        >
                            {filteredHeaders.map(
                                (header: any, hIndex: number) => {
                                    return (
                                        <Box
                                            key={`collapsible-mobile-${hIndex}`}
                                            display="flex"
                                            justifyContent="space-between"
                                            pb="10px"
                                            sx={{
                                                '& .label': {
                                                    color: '#BFBFBF',
                                                },
                                            }}
                                        >
                                            <div className="label">
                                                {header.label}
                                            </div>
                                            <div>
                                                {isLoading ? (
                                                    <TextSkeleton width="100px" />
                                                ) : header.customValue ? (
                                                    header.customValue(row)
                                                ) : (
                                                    row[header.key]
                                                )}
                                            </div>
                                        </Box>
                                    )
                                }
                            )}
                            {isCollapsed && (
                                <Box
                                    sx={{
                                        mt: '10px',
                                        '& .label': {
                                            color: '#BFBFBF',
                                            width: '40%',
                                            mr: '10px',
                                        },
                                    }}
                                >
                                    <span className="info">
                                        Order Information
                                    </span>
                                    {row?.service_type !==
                                        VIEW_TYPE_TARGETED && (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            pb="10px"
                                            mt="10px"
                                        >
                                            <div className="label">
                                                External ID
                                            </div>
                                            <div>{row?.external_order_id}</div>
                                        </Box>
                                    )}

                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        pb="10px"
                                        mt="10px"
                                    >
                                        <div className="label">
                                            Date &amp; Time Ordered
                                        </div>
                                        <div>
                                            {formatDate(row?.created_at ?? '')}
                                        </div>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        pb="10px"
                                    >
                                        <div className="label">Price</div>
                                        <div>{`$${row?.amount ?? 0}`}</div>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        pb="10px"
                                    >
                                        <div className="label">
                                            Amount of ordered views
                                        </div>
                                        <div>{`${
                                            row?.target_count ?? 0
                                        } views`}</div>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        pb="10px"
                                    >
                                        <div className="label">
                                            Service Selected
                                        </div>
                                        <div>
                                            {`${
                                                (options?.services ?? [])[
                                                    row?.service
                                                ] ?? ''
                                            } - ${
                                                (options?.service_types ?? [])[
                                                    row?.service_type
                                                ] ?? ''
                                            }`}
                                        </div>
                                    </Box>
                                    {!isWorker && (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            pb="10px"
                                        >
                                            <div className="label">
                                                Account Transactions
                                            </div>
                                            <Box
                                                onClick={() =>
                                                    window.open(
                                                        `/deposits?userId=${row.user_id}`
                                                    )
                                                }
                                                sx={{
                                                    cursor: 'pointer',
                                                    color: '#27A857',
                                                    textDecoration: 'underline',
                                                    display: 'flex',
                                                    '& span': {
                                                        mr: '5px',
                                                    },
                                                }}
                                            >
                                                <span>
                                                    Check all transactions
                                                </span>
                                                <ArrowOutwardIcon fontSize="small" />
                                            </Box>
                                        </Box>
                                    )}
                                    {!isWorker && (
                                        <>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                pb="10px"
                                            >
                                                <div className="label">
                                                    Latest Reference ID
                                                </div>
                                                <div>{getReference(row)}</div>
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                pb="10px"
                                            >
                                                <div className="label">
                                                    Card Type
                                                </div>
                                                <div>
                                                    {PAYMENT_TYPE[
                                                        row?.transaction
                                                            ?.option ?? 0
                                                    ] ?? ''}
                                                </div>
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                pb="10px"
                                            >
                                                <div className="label">
                                                    Bank Name
                                                </div>
                                                <div>{getBankName(row)}</div>
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                pb="10px"
                                            >
                                                <div className="label">
                                                    Last 4 No. of Account
                                                </div>
                                                <div>
                                                    xxxx-xxxx-xxxx-
                                                    {getLast4(row)}
                                                </div>
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                pb="10px"
                                                sx={{ display: 'none' }}
                                            >
                                                <div className="label">
                                                    Email Address
                                                </div>
                                                <div>''</div>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            )}
                            { isCollapsed &&
                                (row?.issues?.length ?? 0) > 0 && (
                                <Box
                                    sx={{
                                        '& .title': {
                                            mb: '0px',
                                            fontSize: '14px!important',
                                            fontWeight: '500!important',
                                            color: '#656565!important',
                                        },
                                    }}
                                >
                                    <hr />
                                    <p className="title">Campaign Report</p>
                                    <Box
                                        sx={{
                                            '& .issue': {
                                                color: '#27A857',
                                                display: 'flex',
                                                alignItems: 'center',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                },
                                                svg: { paddingRight: '5px' },
                                            },
                                        }}
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <DownloadIssue issues={row.issues} />
                                    </Box>
                                </Box>
                            )}
                            {row?.service_type === VIEW_TYPE_TARGETED && (
                                <Box
                                    sx={{
                                        '& .title': {
                                            mb: '0px',
                                            fontSize: '14px!important',
                                            fontWeight: '500!important',
                                            color: '#656565!important',
                                        },
                                    }}
                                >
                                    <hr />
                                    <p className="title">Targeting Options</p>

                                    <div className="information-detail-column">
                                        <div className="labeled-desc">
                                            <p style={{ color: '#BFBFBF' }}>
                                                Categories
                                            </p>
                                            <div className="chips-container">
                                                {(row?.category_id ?? null) !==
                                                    null && (
                                                    <Chip
                                                        label={
                                                            (options?.categories ??
                                                                {})[
                                                                row?.category_id ??
                                                                    0
                                                            ] ?? ''
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="labeled-desc">
                                            <p style={{ color: '#BFBFBF' }}>
                                                Countries
                                            </p>
                                            <div className="chips-container">
                                                {(row?.countries ?? false) &&
                                                    row.countries.map(
                                                        (
                                                            country: any,
                                                            index: number
                                                        ) => (
                                                            <Chip
                                                                key={`mobile-chip-${index}-${row.id}`}
                                                                label={
                                                                    (options?.countries ??
                                                                        {})[
                                                                        country
                                                                    ] ?? ''
                                                                }
                                                                sx={{
                                                                    display:
                                                                        index >
                                                                        4
                                                                            ? seeMore
                                                                            : '',
                                                                }}
                                                            />
                                                        )
                                                    )}
                                            </div>
                                            {(row?.countries?.length ?? 0) >
                                                5 &&
                                                seeMore === 'none' && (
                                                    <Chip
                                                        className="see-more"
                                                        label="See all "
                                                        onClick={
                                                            showMoreCountries
                                                        }
                                                    />
                                                )}

                                            {(row?.countries?.length ?? 0) >
                                                5 &&
                                                seeMore === '' && (
                                                    <Chip
                                                        className="see-more"
                                                        label="Hide some"
                                                        onClick={
                                                            hideMoreCountries
                                                        }
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </Box>
                            )}
                        </Box>
                    )}
                    {isWorker && (isCollapsed || isDesktop) &&  ((row?.service_type ?? 0) === VIEW_TYPE_TARGETED )&& (
                        <Box
                            className="actions"
                            width={isDesktop ? '30%' : 'unset!important'}
                        >
                            <OrderActionsForm
                                formProps={{
                                    onSubmit: (e: any) => {
                                        onSubmit(e, row.id)
                                    },
                                }}
                                onChange={onChange}
                                options={{
                                    actionTypes: formatOptions(
                                        options?.action_types
                                    ),
                                    statuses: formatOptions(options?.statuses),
                                }}
                                row={row}
                                isLoading={
                                    orderState.isUploading &&
                                    actionPayload.orderId === row.id
                                }
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }
    return (
        <TableWrapper
            entityLabel="orders"
            entity="order"
            headers={headers}
            body={list}
            responsiveHeaders={responsiveHeaders}
            contextMenu={contextMenu}
            total={total}
            currentPage={current_page}
            sortBy={sortBy}
            pageCount={last_page}
            pageFrom={from}
            pageTo={to}
            isLoading={isLoading}
            additionalFilters={
                <FormControl
                    className="upview-form-control common-styled-select"
                    hiddenLabel
                    size="small"
                    sx={{
                        width: '100%',
                        mt: {
                            xs: '20px',
                            sm: '0px',
                        },
                        ml: !isSmallestScreen ? '20px' : 'unset',
                    }}
                >
                    <Select
                        IconComponent={KeyboardArrowDown}
                        inputProps={{
                            'aria-label': 'Without label',
                        }}
                        displayEmpty
                        value={filteredStatus}
                        onChange={(e) => onStatusChange(e.target.value)}
                    >
                        <MenuItem value="">Filter By Status</MenuItem>
                        {Object.entries(options?.statuses ?? {}).map(
                            (status: any, index) => {
                                return (
                                    <MenuItem
                                        key={`filter-status-${index}`}
                                        value={status[0]}
                                    >
                                        {status[1]}
                                    </MenuItem>
                                )
                            }
                        )}
                    </Select>
                </FormControl>
            }
            additionalActions={
                <DownloadForOfflineIcon sx={{ color: '#27A857' }} />
            }
            additionalActionsMobile={
                <Button
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                >
                    Download <DownloadIcon />
                </Button>
            }
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onSearch={onSearch}
            onGetCollapsibleComponent={onGetCollapsibleComponent}
        />
    )
}
