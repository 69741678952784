import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from '@mui/material'
import { IsDesktopScreen } from '../../../helpers/utils.helper'
import CustomButton from '../../customComponents/custom-button.component'
import CustomLabeledFormControl from '../../customComponents/custom-labeled-form-control'
import { KeyboardArrowDown } from '@mui/icons-material'

export default function ApiForm(props: any) {
    const getRootName = (name: string) => {
        const result = name.split('\\').pop()
        return result
    }
    const isDesktop = IsDesktopScreen()

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            {...props.formProps}
            sx={{
                '& label': {
                    fontStyle: 'normal',
                    fontSize: 14,
                    fontWeight: 500,
                    color: '#000',
                    '&.Mui-focused': {
                        color: '#000',
                    },
                },
                '& .MuiFormControl-root': {
                    marginTop: '15px',
                },
            }}
        >
            <div className="form-inputs-container">
                <Box
                    sx={{
                        marginTop: '20px',
                        width: '100%',
                        '& .MuiInputBase-root': {
                          marginTop: '10px',
                        }
                    }}
                >
                    <CustomLabeledFormControl
                        label="API Name"
                        size="small"
                        name="name"
                        placeholder="API Name"
                        value={props.payload.name}
                        error={props.errors.name}
                        onChange={props.onChange('name')}
                    />
                </Box>

                <Box
                    sx={{
                        marginTop: '20px',
                        width: '100%',
                    }}
                >
                    <CustomLabeledFormControl
                        label="Select API Platform "
                        customChildren={
                            <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                                className="common-styled-select"
                            >
                                <Select
                                    IconComponent={KeyboardArrowDown}
                                    value={props.payload.platform_id}
                                    name="platform_id"
                                    onChange={props.onChange('platform_id')}
                                >
                                    <MenuItem value={1}>Youtube</MenuItem>
                                    <MenuItem value={2}>Instagram</MenuItem>
                                </Select>
                            </FormControl>
                        }
                    />
                </Box>

                <Box
                    sx={{
                        marginTop: '20px',
                        width: '100%',
                    }}
                >
                    <CustomLabeledFormControl
                        label="Select API Service "
                        customChildren={
                            <FormControl
                                fullWidth
                                hiddenLabel
                                variant="outlined"
                                size="small"
                                className="common-styled-select"
                            >
                                <Select
                                  IconComponent={KeyboardArrowDown}
                                  value={props.payload.service_class}
                                  name="service_class"
                                  onChange={props.onChange('service_class')}
                                  error={props.errors.service_class}
                                >
                                    {props.options.serviceProviders &&
                                        props.options.serviceProviders.map(
                                            (serviceProvider: any) => (
                                                <MenuItem
                                                    key={
                                                        `view_type_` +
                                                        serviceProvider.id
                                                    }
                                                    value={serviceProvider.id}
                                                >
                                                    {getRootName(
                                                        serviceProvider.name
                                                    )}
                                                </MenuItem>
                                            )
                                        )}
                                </Select>
                            </FormControl>
                        }
                    />
                </Box>

                <Box
                    sx={{
                        marginTop: '20px',
                        width: '100%',
                        '& .MuiInputBase-root': {
                          marginTop: '10px',
                        }
                    }}
                >
                    <CustomLabeledFormControl
                        label="API Key"
                        size="small"
                        name="api_key"
                        value={props.payload.api_key}
                        placeholder="1ec90e2008d240b0f584715ee8dbf014"
                        error={props.errors.api_key}
                        onChange={props.onChange('api_key')}
                    />
                </Box>

                <FormControl
                    sx={{
                        width: '100%',
                        '& .MuiFormGroup-root': {
                            display: 'flex',
                            justifyContent: 'space-between',
                        },
                        '& label': {
                            width: '45%',
                            fontSize: '16px',
                        },
                    }}
                >
                    <FormLabel id="demo-row-radio-buttons-group-label">
                        API Status
                    </FormLabel>
                    <RadioGroup
                        row
                        name="is_active"
                        onChange={props.onChange('is_active')}
                        value={props.payload.is_active}
                    >
                        <FormControlLabel
                            value={1}
                            control={
                                <Radio
                                    sx={{
                                        color: '#D1D1D1',
                                        '&.Mui-checked': {
                                            color: '#27A857',
                                        },
                                    }}
                                />
                            }
                            label="Active"
                        />
                        <FormControlLabel
                            value={0}
                            control={
                                <Radio
                                    sx={{
                                        color: '#D1D1D1',
                                        '&.Mui-checked': {
                                            color: '#27A857',
                                        },
                                    }}
                                />
                            }
                            label="Disable"
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <div>
                <CustomButton
                    isLoading={props.loading}
                    label={props.btnText}
                    type="submit"
                    sx={{
                        padding: '10px 40px!important',
                        mt: '20px',
                        float: isDesktop ? 'right' : 'unset',
                        width: !isDesktop ? '100%' : 'unset',
                    }}
                    className="upviews-button"
                />
            </div>
        </Box>
    )
}
