const endpoints = {
    CSRF_TOKEN: '/sanctum/csrf-cookie',
    GET_VIP_INFO: '/api/vip',
    LOGIN: '/api/login',
    REGISTER: '/api/register',
    NEW_ORDER: '/api/orders',
    GET_ORDERS: '/api/orders',
    RESEND_ORDER: '/api/orders/{0}/resend',
    CANCEL_ORDER: '/api/orders/{0}/cancel',
    ORDER_ACTION: '/api/orders/{0}/action',
    INFO: 'api/me',
    OPTIONS: 'api/options',
    UPLOAD_THUMB: '/api/orders/{0}/thumbnail',
    UPLOAD_ISSUE: '/api/orders/{0}/issue',
    DOWNLOAD_ISSUE: '/api/files/{0}/download',
    GET_STATISTICS: '/api/account/statistic',
    NEW_TRANSACTION: '/api/transactions/deposit',
    GET_TRANSACTION: '/api/transactions',
    UPDATE_TRANSACTION: '/api/transactions/{0}',
    GET_WALLET: '/api/account/wallet',
    GET_USERS: '/api/users',
    BAN_USER_IP: '/api/users/{0}/ban_ip',
    BAN_USER_ACCOUNT: '/api/users/{0}/ban_account',
    PAUSE_USER_ACCOUNT: '/api/users/{0}/pause_account',
    ACTIVATE_ACCOUNT: '/api/users/{0}/activate_account',
    UPDATE_PROFILE: '/api/users/{0}',
    NEW_PROFILE: '/api/users',
    UPDATE_PHOTO: '/api/users/{0}/photo',
    GET_API_PROVIDERS: '/api/api_providers',
    GET_FILTERS: '/api/filters',
    NEW_FILTER: '/api/filters',
    EDIT_FILTER: '/api/filters/{0}',
    DELETE_FILTER: '/api/filters/{0}',
    NEW_API_PROVIDER: '/api/api_providers',
    EDIT_API_PROVIDER: '/api/api_providers/{0}',
    NEW_SUPPORT: '/api/supports',
    RECOVERY: '/api/password/email',
    RESET: '/api/password/reset',
}

const endpointsRequiringCSRFToken = [endpoints.LOGIN, endpoints.REGISTER]

export const requiresCSRFToken = (endpoint: string): boolean =>
    endpointsRequiringCSRFToken.includes(endpoint)

export default endpoints
