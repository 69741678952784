import TableWrapper from '../../wrappers/table-wrapper.component'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { formatDate } from '../../../helpers/utils.helper'
import { Box } from '@mui/material'
import {
    PAYMENT_STATUS,
    PAYMENT_TYPE,
    TRANSACTION_TYPE,
} from '../../../types/transaction_options.types'
import { useState } from 'react'

import {
    getBankName,
    getLast4,
    getReference,
} from '../../../services/deposit.service'

const Deposits = (props: any) => {
    const {
        meta,
        isLoading,
        onPageChange,
        isDesktop,
        list,
        onSort,
        approvePayment,
        cancelPayment,
        onSearch,
    } = props

    const { current_page, total, last_page = 0, from = 0, to = 0 } = meta || {}
    const [sortBy, setSortBy] = useState('')
    const headers = [
        {
            key: 'created_at',
            label: 'Date & Time',
            customValue: (row: any) => formatDate(row.created_at, 'l LT'),
        },
        { key: 'code', label: 'Transaction ID' },
        { 
          key: 'type', 
          label: 'Type',
          customValue: (row: any) => TRANSACTION_TYPE[row?.type ?? 0]
        },
        {
            key: 'amount',
            label: 'Amount',
            customValue: (row: any) => (row?.amount ?? 0).toFixed(2),
        },

        {
            key: 'reference',
            label: 'Reference ID',
            customValue: getReference,
        },
        {
            key: 'bank_name',
            label: 'Bank Name',
            customValue: getBankName,
        },
        {
            key: 'account_number',
            label: 'Last 4 No. of Acc',
            customValue: getLast4,
        },
        {
            key: 'option',
            label: 'Payment Method',
            customValue: (row: any) => PAYMENT_TYPE[row?.option ?? 0],
        },
        {
            key: 'status',
            label: 'Status',
            customValue: (row: any) => {
                const { label, color } = PAYMENT_STATUS[row.status] || {}
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            '& .status-icon': {
                                height: '8px',
                                width: '8px',
                                border: '3px solid #FF4848',
                                borderRadius: '50%',
                                mr: '5px',
                                mb: '4px',
                            },
                            color,
                            '.status-icon': { borderColor: color },
                        }}
                    >
                        <div className="status-icon" /> {label}
                    </Box>
                )
            },
        },
    ]

    const onSortChange = (value: string) => {
        setSortBy(value)
        onSort(value)
    }

    const contextMenu = [
        {
            callBack: (row: any) => approvePayment(row?.id),
            label: 'Approve Deposit',
            icon: <CheckBoxIcon />,
        },
        {
            callBack: (row: any) => cancelPayment(row?.id),
            label: 'Cancel Deposit',
            icon: <DisabledByDefaultIcon />,
        },
    ]

    return (
        <TableWrapper
            entityLabel="deposits"
            headers={headers}
            responsiveHeaders={[
                {
                    key: 'created_at',
                    label: `Date & Time`,
                    customValue: (row: any) =>
                        formatDate(row.created_at, 'l LT'),
                },
            ]}
            body={list}
            contextMenu={contextMenu}
            total={total}
            currentPage={current_page}
            pageCount={last_page}
            pageFrom={from}
            pageTo={to}
            isLoading={isLoading}
            isDesktop={isDesktop}
            onPageChange={onPageChange}
            sortBy={sortBy}
            onSortChange={onSortChange}
            onSearch={onSearch}
        />
    )
}

export default Deposits
