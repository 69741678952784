import '../assets/css/register.css'
import { Box, Button, Grid } from '@mui/material'
import Logo from '../assets/images/logo.svg'
import RegisterForm from '../components/register.form.component'
import { ChangeEvent, SyntheticEvent, useState } from 'react'
import store, { RootState } from '../store'
import { register } from '../actions/auth.action'
import { useSelector } from 'react-redux'
import { RegisterState } from '../reducers/register.reducer'
import Notification from '../components/notification.components'
import { REGISTER_RESET } from '../constants/user.constants'
import ConfirmationModal from '../components/confirmation-modal.component'
import { IsDesktopScreen, IsSmallestScreen } from '../helpers/utils.helper'
import { TYPES_CONST } from '../env'
import { UserState } from '../reducers/user.reducer'
import { getToken } from '../services/auth.service'
import { Navigate } from 'react-router'
import GoogleLogo from '../assets/images/google-logo.png'
import CustomTermsOfUseLinkComponent from '../components/customComponents/custom-terms-of-use-link-component'

const RegisterScreen = () => {
    const auth = useSelector<RootState, UserState>((state) => state.userLogin)
    const isAuthenticated =
        getToken() !== null && auth.loading === false && auth.isAuth

    const roles = auth?.info?.roles ?? []
    const isCustomer = auth?.info?.roles && roles.length === 0

    const isDesktop = IsDesktopScreen()
    const isSmallestScreen = IsSmallestScreen()
    const rootState = useSelector<RootState, RegisterState>(
        (state) => state.register
    )
    const [payload, setPayload] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        gender: null,
    })

    const defaultErrors = {
        name: false,
        email: false,
        password: false,
        confirmPassword: false,
    }

    const [errors, setErrors] = useState(defaultErrors)

    const validate = () => {
        let dataFields = [
            {
                key: 'name',
                isValid: payload.name !== '',
            },
            {
                key: 'email',
                isValid: payload.email !== '',
            },
            {
                key: 'password',
                isValid: payload.password !== '',
            },
            {
                key: 'confirmPassword',
                isValid:
                    payload.confirmPassword !== '' &&
                    payload.confirmPassword === payload.password,
            },
            {
                key: 'gender',
                isValid: payload.gender !== null,
            },
        ]

        return {
            data: dataFields,
            isValid: dataFields.every((data) => data.isValid),
        }
    }

    const onSubmit = async (event: SyntheticEvent) => {
        event.preventDefault()

        const validation = validate()

        if (!validation.isValid) {
            setErrors(
                validation.data.reduce((prevField, field) => {
                    return { ...prevField, [field.key]: !field.isValid }
                }, defaultErrors)
            )
            return
        }

        store.dispatch(register(payload))
    }

    const onChange =
        (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            setPayload((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

    const queryParams = new URLSearchParams(window.location.search)

    const service = queryParams.get('service')
    const serviceType = queryParams.get('service_type')
    const targetCount = queryParams.get('target_count')
    const hasParams = service || serviceType || targetCount
    if (hasParams && isCustomer) {
        service && localStorage.setItem('service', service)
        serviceType && localStorage.setItem('service_type', serviceType)
        targetCount && localStorage.setItem('target_count', targetCount)
    }

    const routeAuthenticated = hasParams ? '/orders/new' : '/'

    const LoginWithGoogle = () => {
        window.location.href = TYPES_CONST.BASE_URL + 'api/login/google'
        return
    }

    return isAuthenticated ? (
        <Navigate to={routeAuthenticated} />
    ) : (
        <Grid container className="register-body">
            <Grid item xs={12} sm={12} md={5} className="register-bg">
                <a href={TYPES_CONST.MARKETING_URL}>
                    <img src={Logo} alt="Upviews" />
                </a>
            </Grid>
            <Grid
                item
                md={7}
                xs={12}
                sm={12}
                className="form-grid"
                sx={{
                    '& a': { textDecoration: 'none' },
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <p className="header-link header-link-top">
                    Already have an account? <a href="/login">Sign In!</a>
                </p>
                <Grid
                    item
                    md={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: isDesktop ? '400px' : '100%',
                            margin: isSmallestScreen ? '5px' : 'unset',
                        }}
                        className="form-container"
                    >
                        <Box className="register-header">
                            <h4>Never Struggle for Views Again!</h4>
                            <p>Getting started is super easy</p>
                            <div>
                                <Button
                                    className="login-with-google"
                                    onClick={LoginWithGoogle}
                                >
                                    <img
                                        src={GoogleLogo}
                                        alt="Login with google"
                                    />
                                    Google
                                </Button>
                            </div>
                        </Box>
                        <Box
                            sx={{
                                padding: '0 5px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                '& div': { width: '100%' },
                                '& div.line': {
                                    borderTop: '1px solid #DBDBDB',
                                },
                                '& div.label': {
                                    textAlign: 'center',
                                    fontSize: '13px',
                                },
                            }}
                        >
                            <div className="line" />
                            <div className="label">Or continue with</div>
                            <div className="line" />
                        </Box>
                        <RegisterForm
                            className="register-form"
                            loading={rootState.loading}
                            formProps={{ onSubmit: onSubmit }}
                            onChange={onChange}
                            errors={errors}
                        />
                    </Box>

                    <Box
                        sx={{ position: 'fixed', bottom: '33px' }}
                        className="login-action-bottom"
                    >
                        <p>
                            Don't have an account?{' '}
                            <a href="/register">Sign Up!</a>
                        </p>
                    </Box>
                </Grid>
                <Box
                    className="agreement"
                    sx={{
                        mb: isSmallestScreen ? '50px' : 'unset',
                    }}
                >
                    <p>
                        By continuing you indicate that you read and agreed to
                        the <CustomTermsOfUseLinkComponent />
                    </p>

                    <p className="header-link-bottom">
                        Already have an account? <a href="/login">Sign In!</a>
                    </p>
                </Box>
            </Grid>
            <Notification
                message={
                    rootState.payload?.message ?? 'Sign-up error has occurred.'
                }
                open={rootState.showNotification}
                severity="error"
                onClose={() =>
                    store.dispatch({
                        type: REGISTER_RESET,
                    })
                }
            />
            <ConfirmationModal
                open={rootState.isRegistered}
                onClose={() => {
                    store.dispatch({
                        type: REGISTER_RESET,
                    })
                }}
            />
        </Grid>
    )
}

export default RegisterScreen
