import { format } from 'react-string-format'
import {
    IG_SERVICE_FOLLOWERS,
    IG_SERVICE_LIKES,
    ORDER_THRESHOLD,
    SERVICE_LIKE,
    SERVICE_SUBSCRIBE,
    SERVICE_VIEW,
} from '../constants/orders.constants'
import API_INSTANCE, { objectToQueryParams } from './api'
import endpoints from './endpoints'

export const OrderService = {
    bundlePayload: ['is_bundle', 'urls', 'service_type', 'service'],
    singlePayload: [
        'is_bundle',
        'youtube_url',
        'target_count',
        'service_type',
        'service',
        'thumbnail_urls',
        'thumbnails',
        'category_id',
        'countries',
        'force'
    ],
    filterPayload: (isBundle: boolean, payload: any): any => {
        const validFields = isBundle
            ? OrderService.bundlePayload
            : OrderService.singlePayload

        return Object.fromEntries(
            Object.entries(payload).filter(([key]) => validFields.includes(key))
        )
    },
    defaultIndexParams: () => {
        return {
            per_page: ORDER_THRESHOLD,
        }
    },
    new: async (fields: any) => {
        return API_INSTANCE.post(endpoints.NEW_ORDER, fields)
    },
    index: async (
        page: number = 1,
        search: string | null = null,
        sort: string | null = null,
        status: string | null = null
    ) => {
        let params = {
            ...OrderService.defaultIndexParams(),
            ...{ page: page, search: search, sort_by: sort, status },
        }

        return API_INSTANCE.get(
            endpoints.GET_ORDERS + '?' + objectToQueryParams(params)
        )
    },
    uploadThumb: async (files: Array<File>, order: number) => {
        let formData = new FormData()
        for (var i = 0; i < files.length; i++) {
            formData.append('thumbnails[]', files[i])
        }
        return API_INSTANCE.post(
            format(endpoints.UPLOAD_THUMB, order),
            formData
        )
    },
    uploadIssue: async (files: Array<File>, order: number) => {
        let formData = new FormData()
        for (var i = 0; i < files.length; i++) {
            formData.append('issues[]', files[i])
        }
        return API_INSTANCE.post(
            format(endpoints.UPLOAD_ISSUE, order),
            formData
        )
    },
    resend: async (orderId: number) => {
        return API_INSTANCE.post(format(endpoints.RESEND_ORDER, orderId))
    },
    cancel: async (orderId: number) => {
        return API_INSTANCE.post(format(endpoints.CANCEL_ORDER, orderId))
    },
    action: async (orderId: number, fields: any) => {
        return API_INSTANCE.post(
            format(endpoints.ORDER_ACTION, orderId),
            fields
        )
    },
    downloadIssue: async (issueId: number) => {
        return API_INSTANCE.get(format(endpoints.DOWNLOAD_ISSUE, issueId), {
            responseType: 'blob',
        })
    },
    getExternalServiceIdFromServiceType: (
        serviceType: any,
        externalServices: any
    ) => {
        if (!serviceType || !externalServices) {
            return null
        }
        let externalService = Object.entries(externalServices).filter(
            (service: any) =>
                (service[1]['service_type'] ?? 0) * 1 === serviceType * 1
        )

        if (!externalService[0]) {
            return null
        }

        return externalService[0][0] ?? null
    },
    getTargetSubject: (service: any) => {
        if (!service) {
            return null
        }

        if (service === SERVICE_LIKE) {
            return 'likes'
        }

        if (service === SERVICE_SUBSCRIBE) {
            return 'subscribers'
        }

        if (service === SERVICE_VIEW) {
            return 'views'
        }

        if (service === IG_SERVICE_FOLLOWERS) {
            return 'followers'
        }

        if (service === IG_SERVICE_LIKES) {
          return 'likes'
      }

        return null
    },
}
