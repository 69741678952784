import { List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import AverageRevenue from '../../assets/images/average-revenue-icon.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import { collectDataSet, collectLabels } from '../../helpers/statistic.helper';
import TextSkeleton from '../skeletons/text.skeleton';
import RectangularSkeleton from '../skeletons/rectangular.skeleton';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      display: false,
    },
    x: {
      display: false,
    },
  },
  pointRadius: 0,
};

export const defaultDataSet = {
  label: 'data',
  data: [0],
  borderColor: '#FFBE0A',
  lineTension: 0.5,
  radius: 7,
  fill: false,
};

export default function AverageRevenueGraph(props: any) {
  const isLoading = props.isLoading;
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState('Monthly');
  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState(0);

  const [data, setData] = useState({
    labels: [''],
    datasets: [defaultDataSet],
  });

  useEffect(() => {
    if ((isLoaded === false && props.data?.data) ?? null) {
      let dataSet = collectDataSet(props.data.data);
      let currentLabels = collectLabels(dataSet);
      let dataSetTotal = dataSet.reduce((a: any, b: any) => parseInt(a) + parseInt(b), 0);
      setData({
        labels: currentLabels,
        datasets: [{ ...defaultDataSet, data: dataSet }],
      });

      let raw = dataSetTotal === 0 ? 0 : dataSetTotal / dataSet.length;
      setTotal(Math.round(raw * 100) / 100);
      setIsLoaded(true);
    }
  }, [isLoaded, props, data]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, filter: string) => {
    setFilter(filter);
    let dataSet = collectDataSet(props.data.data, filter);
    let dataSetTotal = dataSet.reduce((a: any, b: any) => parseInt(a) + parseInt(b), 0);
    let currentLabels = collectLabels(dataSet);
    setData({
      labels: currentLabels,
      datasets: [{ ...defaultDataSet, data: dataSet }],
    });
    let raw = dataSetTotal === 0 ? 0 : dataSetTotal / dataSet.length;
    setTotal(Math.round(raw * 100) / 100);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'graphs-popover' : undefined;

  return (
    <div className="average-revenue-container">
      <div className="header">
        <img src={AverageRevenue} alt="" />
        <p aria-describedby={id} onClick={handleClick}>
          <span>{filter}</span> <KeyboardArrowDownIcon />
        </p>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="popover"
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => handleFilter(event, 'Weekly')}>
              <ListItemText primary="Weekly" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => handleFilter(event, 'Monthly')}>
              <ListItemText primary="Monthly" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => handleFilter(event, 'Yearly')}>
              <ListItemText primary="Yearly" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <p className="amount">Average Revenue</p>
      <h1>{isLoading ? <TextSkeleton width='100px' /> : `$${total}`}</h1>
      {
        isLoading
          ? <RectangularSkeleton width='100%' height={145} />
          : <Line options={options} data={data} />
      }
    </div>
  );
}
