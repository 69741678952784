import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Avatar,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    AppBar,
    Toolbar,
    Stack,
    IconButton,
    Collapse,
    ListItemButton,
} from '@mui/material'
import { Box } from '@mui/system'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AddIcon from '@mui/icons-material/Add'

import Logo from '../../assets/images/logo.svg'
import store, { RootState } from '../../store'
import { logout } from '../../actions/auth.action'
import { TYPE_SUPER_ADMIN, TYPE_WORKER } from '../../types/user.types'
import { UserState } from '../../reducers/user.reducer'
import { useLocation, useNavigate } from 'react-router'
import IconSkeleton from '../skeletons/icon.skeleton'
import TextSkeleton from '../skeletons/text.skeleton'
import ProfileMenu from './profile-menu.component'
import { ReactComponent as DashboardIcon } from '../../assets/images/icons/dashboard.svg'
import { ReactComponent as OrdersIcon } from '../../assets/images/icons/orders.svg'
import { ReactComponent as StartCampaignIcon } from '../../assets/images/icons/start-campaign.svg'
import { ReactComponent as PaymentHistoryIcon } from '../../assets/images/icons/payment-history.svg'
import { ReactComponent as SupportIcon } from '../../assets/images/icons/support.svg'
import { ReactComponent as FiltersIcon } from '../../assets/images/icons/filter.svg'
import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg'
import { ReactComponent as SettingsUserIcon } from '../../assets/images/icons/settings-user.svg'
import { ReactComponent as SettingsAPIIcon } from '../../assets/images/icons/settings-api.svg'
import { ReactComponent as LogoutIcon } from '../../assets/images/icons/logout.svg'
import { ReactComponent as WalletIcon } from '../../assets/images/icons/wallet.svg'
import { getCurrentUser } from '../../services/auth.service'

type menuProps = {
    path: string
    label: string
    icon: JSX.Element
    roles: Array<string>
    subItems?: any
}

export const navItems = [
    {
        path: '/',
        label: 'Dashboard',
        icon: <DashboardIcon />,
        roles: ['admin', 'customer'],
    },
    {
        path: '/orders',
        label: 'Orders',
        icon: <OrdersIcon />,
        roles: ['admin', 'worker', 'customer'],
    },
    {
        path: '/deposits',
        label: 'Transactions',
        icon: <AccountBalanceWalletIcon />,
        roles: ['admin'],
    },
    {
        path: '/filters',
        label: 'Filters',
        icon: <FiltersIcon />,
        roles: ['admin'],
    },
    {
        path: '/settings',
        label: 'Settings',
        icon: <SettingsIcon />,
        roles: ['admin'],
        subItems: [
            {
                path: '/settings/user',
                label: 'User Settings',
                icon: <SettingsUserIcon />,
            },
            {
                path: '/settings/api',
                label: 'API Settings',
                icon: (
                    <span className="settings-api">
                        <SettingsAPIIcon />
                    </span>
                ),
            },
        ],
    },
    {
        path: '/orders/new',
        label: 'Start Campaign',
        icon: <StartCampaignIcon />,
        roles: ['customer'],
    },
    {
        path: '/transactions',
        label: 'Payment History',
        icon: <PaymentHistoryIcon />,
        roles: ['customer'],
    },
    {
        path: '/support',
        label: 'Support',
        icon: <SupportIcon />,
        roles: ['customer'],
    },
]

export default function LeftSideBar(props: any) {
    const { isDesktop, showRightSideBar, isLoading } = props
    const user = useSelector<RootState, UserState>((state) => state.userLogin)
    let navigate = useNavigate()
    let location = useLocation()
    let upviewsVipLogo, vipLogo = ''


    const onLogout = async (e: SyntheticEvent) => {
        e.preventDefault()
        store.dispatch(logout())
        setTimeout(() => window.location.reload())
    }

    const [role, setRole] = useState('admin')
    // defaulted settings menu to open; used path as key because it is unique per user
    const [subMenuOpen, setSubMenuOpen] = useState<any>({
        '/settings': location.pathname.includes('/settings'),
    })
    const [subMenu, setSubMenu] = useState<any>(null)
    const isCustomer = role === 'customer'

    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

    useEffect(() => {
        if (user.info) {
            let roles = user?.info?.roles ?? []
            if (roles.includes(TYPE_SUPER_ADMIN)) setRole('admin')
            if (roles.includes(TYPE_WORKER)) setRole('worker')
            if (roles.length === 0) setRole('customer')
        }
    }, [role, user])

    const cacheUser = getCurrentUser()
    const isVIP = cacheUser?.is_vip ?? false
    const vipInfo = cacheUser?.vip ?? {}
    const vipId = vipInfo?.settings?.id ?? 0

    if(isVIP) {
      require(`../../assets/css/vip/${vipId}.css`)

      upviewsVipLogo = require(`../../assets/images/vip/${vipId}/upviews-vip-logo.svg`)
      vipLogo = require(`../../assets/images/vip/${vipId}/logo-big.png`)
    }

    const getSubItems = (
        subItems: any,
        index: number,
        isMobile: boolean = false,
        title: string = ''
    ) => {
        return (
            <React.Fragment
                key={`fragment-sub-menu-${index}-${isMobile ? 'mobile' : ''}`}
            >
                {title ? <h1>{title}</h1> : null}
                {subItems.map((subMenu: menuProps, index2: number) => (
                    <List
                        key={`sub-menu-${index}-${index2}-${
                            isMobile ? 'mobile' : ''
                        }`}
                        className={`sub-menu-list ${
                            location.pathname === subMenu.path ? 'active' : ''
                        }`}
                        component="div"
                        disablePadding
                    >
                        <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => {
                                if (isLoading) return
                                navigate(subMenu.path)
                                setSubMenu(null)
                            }}
                        >
                            <ListItemIcon>{subMenu.icon}</ListItemIcon>
                            <ListItemText primary={subMenu.label} />
                        </ListItemButton>
                    </List>
                ))}
            </React.Fragment>
        )
    }

    const getDrawerMenuList = (isMobile: boolean = false) => {
        const menuList = navItems.filter((n: any) => n.roles.includes(role))
        return (
            <List
                key={isMobile ? 'list-mobile' : 'list-desktop'}
                className={isMobile ? 'mobile-nav' : ''}
            >
                {menuList.map((menu: menuProps, index: number) => {
                    const { path = '', label, icon, subItems } = menu
                    const hasSubMenu = !!subItems?.length
                    const to = hasSubMenu ? subItems[0].path : path
                    const isActive = hasSubMenu
                        ? location.pathname.includes(path)
                        : location.pathname === path
                    const isMenuOpen =
                        isActive && hasSubMenu && subMenuOpen[path]
                    return (
                        <React.Fragment
                            key={`menu-${index}-${isMobile ? 'mobile' : ''}`}
                        >
                            <ListItem
                                button
                                onClick={() => {
                                    if (isLoading) return
                                    navigate(to)
                                    setSubMenuOpen({
                                        ...Object.keys(subMenuOpen).reduce(
                                            (acc, key) => ({
                                                ...acc,
                                                [key]: false,
                                            }),
                                            {}
                                        ),
                                        [path]: !subMenuOpen[path],
                                    })
                                    if (isMobile) {
                                        setSubMenu(
                                            hasSubMenu && !subMenu
                                                ? getSubItems(
                                                      subItems,
                                                      index,
                                                      isMobile,
                                                      label
                                                  )
                                                : null
                                        )
                                    }
                                }}
                                className={`${
                                    hasSubMenu ? 'has-sub-menu' : ''
                                }${!isLoading && isActive ? ' active' : ''}`}
                            >
                                <ListItemIcon>
                                    <Avatar>
                                        {isLoading ? <IconSkeleton /> : icon}
                                    </Avatar>
                                </ListItemIcon>
                                {isLoading && !isMobile ? (
                                    <TextSkeleton />
                                ) : (
                                    <ListItemText primary={label} />
                                )}
                                {!isLoading &&
                                    hasSubMenu &&
                                    !isMobile &&
                                    (isMenuOpen ? (
                                        <ExpandLess className="right-icon" />
                                    ) : (
                                        <ExpandMore className="right-icon" />
                                    ))}
                            </ListItem>
                            {!isLoading && hasSubMenu && !isMobile && (
                                <Collapse
                                    in={isMenuOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {getSubItems(subItems, index, isMobile)}
                                </Collapse>
                            )}
                        </React.Fragment>
                    )
                })}
                <ListItem className="logout" button onClick={onLogout}>
                    <ListItemIcon>
                        <Avatar>
                            {isLoading ? <IconSkeleton /> : <LogoutIcon />}
                        </Avatar>
                    </ListItemIcon>
                    {isLoading && !isMobile ? (
                        <TextSkeleton />
                    ) : (
                        <ListItemText primary="Logout" />
                    )}
                </ListItem>
            </List>
        )
    }

    return (
        <>
            {isDesktop && (
                <Drawer open variant="permanent" className={isVIP ? 'drawer drawer-vip' : 'drawer'}>
                    <div className="logo">
                        <img src={isVIP ? upviewsVipLogo : Logo} alt="Upviews" />
                    </div>
                    {getDrawerMenuList()}

                    {isVIP && 
                      <div className="vip-logo">
                          <img src={vipLogo} alt="Salxco" />
                      </div>
                    }
                </Drawer>
            )}

            {!isDesktop && (
                <>
                    <AppBar
                        position="fixed"
                        sx={{
                            width: '100%',
                            backgroundColor: '#fff',
                            padding: '14px 10px',
                            borderBottomLeftRadius: 40,
                            borderBottomRightRadius: 40,
                            boxShadow:
                                '0px 2px 2px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%)',
                            zIndex: '10000',
                        }}
                    >
                        <Toolbar
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <img src={Logo} alt="Upviews" />
                            </Box>
                            <Box display="flex" alignItems="center">
                                {/* {isCustomer && (
                                    <IconButton
                                        className={`upviews-badge`}
                                        color="inherit"
                                        onClick={showRightSideBar}
                                    >
                                        <Badge badgeContent="">
                                            <Avatar>
                                                <NotificationsIcon />
                                            </Avatar>
                                        </Badge>
                                    </IconButton>
                                )} */}
                                <Stack direction="row" spacing={2} mr="5px">
                                    <IconButton
                                        onClick={() =>
                                            setIsProfileMenuOpen(
                                                !isProfileMenuOpen
                                            )
                                        }
                                    >
                                        {user?.info?.avatar ? (
                                            <Avatar
                                                sx={{
                                                    width: 38,
                                                    height: 38,
                                                    bgcolor:
                                                        '#' +
                                                            user?.info
                                                                ?.avatar ??
                                                        'fff',
                                                }}
                                                src={require('../../assets/images/avatars/' +
                                                    user?.info?.avatar +
                                                    '.png')}
                                            />
                                        ) : (
                                            <Avatar
                                                sx={{
                                                    width: 38,
                                                    height: 38,
                                                    bgcolor: '#2ecc71',
                                                }}
                                            >
                                                {user?.info?.name?.charAt(0)}
                                            </Avatar>
                                        )}
                                    </IconButton>
                                </Stack>
                                {isCustomer && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            backgroundColor: '#F0F2F5',
                                            boxShadow:
                                                '0px 40px 60px rgba(128, 144, 155, 0.25)',
                                            borderRadius: '30px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            pl: '15px',
                                            '& p': {
                                                margin: '0 !important',
                                                fontSize: '16px',
                                                fontWeight: '800',
                                                color: '#27A857',
                                                padding: '0 10px',
                                            },
                                            '& svg.wallet': {
                                                fontSize: '16px',
                                            },
                                            '& svg.add': {
                                                width: 'auto !important',
                                                fontSize: '18px',
                                                padding: '11px !important',
                                                backgroundColor: '#1AA928',
                                                borderRadius: '50%',
                                            },
                                        }}
                                    >
                                        <WalletIcon className="wallet" />
                                        <p>${props.wallet?.balance ?? 0}</p>
                                        <AddIcon
                                            className="add"
                                            onClick={showRightSideBar}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <AppBar
                        position="fixed"
                        sx={{
                            top: 'unset!important',
                            bottom: '0px',
                            display: { sm: 'block', md: 'none' },
                            width: '100%',
                            backgroundColor: '#fff',
                            padding: '14px 10px',
                            borderTopLeftRadius: 40,
                            borderTopRightRadius: 40,
                            boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 10%)',
                            zIndex: '10000',
                            '& ul.mobile-nav': {
                                display: 'flex',
                                justifyContent: 'space-around',
                                '& .MuiButtonBase-root': {
                                    p: 'unset!important',
                                    width: 'unset',
                                    '& .MuiAvatar-root': {
                                        bgcolor: 'unset',
                                        color: '#ABB5BA',
                                        width: 'unset',
                                        height: 'unset',
                                    },
                                    '&.active .MuiAvatar-root svg path': {
                                        fill: '#FF3E3E!important',
                                    },
                                    '& .MuiListItemIcon-root': {
                                        minWidth: 'unset',
                                    },
                                },
                                '& .MuiListItemText-root': { display: 'none' },
                            },
                        }}
                    >
                        {getDrawerMenuList(true)}
                    </AppBar>
                </>
            )}
            <Drawer
                sx={{
                    flexShrink: 0,
                    '.MuiPaper-root': {
                        padding: '25px',
                        pt: '140px',
                        background: '#F9F9F9',
                        height: '100%',
                        h1: {
                            fontSize: '24px',
                            fontWeight: 'bold',
                            mb: '21px',
                        },
                        '& .sub-menu-list': {
                            bgcolor: '#fff',
                            height: '64px',
                            mb: '16px',
                            display: 'flex',
                            '& .MuiListItemButton-root': {
                                pl: '20px',
                                boxShadow: '0px 0px 7px 0px rgb(0 0 0 / 10%)',
                            },
                        },
                    },
                }}
                variant="temporary"
                anchor="top"
                className="mobile"
                open={!!subMenu && !isDesktop}
            >
                {subMenu}
            </Drawer>
            <Drawer
                sx={{
                    flexShrink: 0,
                    '.MuiPaper-root': {
                        padding: '25px',
                        pt: '140px',
                        background: '#F9F9F9',
                        height: '100%',
                        h1: {
                            fontSize: '24px',
                            fontWeight: 'bold',
                            mb: '21px',
                        },
                        '& .sub-menu-list': {
                            bgcolor: '#fff',
                            height: '64px',
                            padding: '20px',
                            mb: '20px',
                            boxShadow: '0px 0px 7px 0px rgb(0 0 0 / 10%)',
                        },
                    },
                }}
                variant="temporary"
                anchor="top"
                className="mobile"
                open={isProfileMenuOpen && !isDesktop}
            >
                <h1>My Profile</h1>
                <ProfileMenu
                    onClose={() => setIsProfileMenuOpen(false)}
                    className="sub-menu-list"
                />
            </Drawer>
        </>
    )
}
